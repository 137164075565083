#no_class_avg_data {
  height: 100%;
  display: flex;
  position: relative;
  padding: 30px;
  align-items: center;
  justify-content: center;
  background-color: rgba(0, 0, 0, 0.2);
  margin: 5px;

  p {
    position: absolute;
  }
}

#class_averages_bar_chart_container {
  overflow-x: auto;
  grid-area: b;
  height: fit-content;
}
