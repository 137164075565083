%action-btn-on-hover-txt {
  background-color: #2f353a;
  color: white;
  font-size: 120%;
  position: absolute;
  padding: 3px 6px;
  bottom: -1.6em;
  white-space: nowrap;
  box-shadow: 1px 1px 3px #222222;
  opacity: 0;
  border-radius: 5px;
  z-index: 99999;
  visibility: hidden;
}

[assignment-data-title]:after {
  @extend %action-btn-on-hover-txt;
  left: unset;
  right: 0px;
  bottom: unset;
  top: -30px;
  content: attr(assignment-data-title);
}

[assignment-data-title]:hover:after {
  opacity: 1;
  transition: all 0.1s ease 0.5s;
  visibility: visible;
}

[lesson-preview-data-title]:after {
  @extend %action-btn-on-hover-txt;
  left: unset;
  right: 0px;
  bottom: unset;
  top: -10px;
  content: attr(lesson-preview-data-title);
}

[lesson-preview-data-title]:hover:after {
  opacity: 1;
  transition: all 0.1s ease 0.5s;
  visibility: visible;
}

button[lesson-preview-data-title]:disabled {
  opacity: 0.8 !important;
}

#assignment_table_container {
  border-radius: 10px;
  border: 1px solid #dfe2e6;
  width: 100%;

  #assignment_table_pagination_container {
    text-align: center;
    display: flex;
    justify-content: center;
    margin: 10px;

    select {
      height: fit-content;
      width: 60px;
      margin: 0px 5px 0px 5px;
    }
  }

  %assignments_list_grid {
    display: grid;
    grid-template-columns:
      200px 100px calc((100% - 546px) / 4)
      calc((100% - 546px) / 4) calc((100% - 546px) / 4) calc((100% - 546px) / 4)
      65px 65px 100px;
    grid-gap: 2px; // 16px total
    text-align: center;

    h5,
    p {
      display: flex;
      align-items: center;
      justify-content: center;
      margin: 0;
      word-break: break-word;
      hyphens: auto;
    }
  }

  #assignments_list_header {
    @extend %assignments_list_grid;
    background-color: #f2f2f2;
    font-weight: bold;
    align-items: center;

    p {
      margin: 5px 0px 5px 0px;
    }
  }

  .assignments_table_row {
    @extend %assignments_list_grid;
    border-top: 1px solid #d7d7d7;
    cursor: pointer;

    %assignment_status {
      height: fit-content;
      padding: 5px;
      margin: auto;
    }

    .assignment_data_with_zoom_label {
      display: flex;
      align-items: center;
      justify-content: center;
      flex: 2 1 auto;
    }
  }
  .assignment_status_green {
    @extend %assignment_status;
    border: 1px solid #1c842f;
    color: #1c842f;
    background-color: #d7edc5;
  }

  .assignment_status_yellow {
    @extend %assignment_status;
    border: 1px solid #b6a402;
    color: #806420;
    background-color: #fff3cd;
  }

  .assignment_status_red {
    @extend %assignment_status;
    border: 1px solid #cb444a;
    color: #824346;
    background-color: #ffcec8;
  }

  .assignment_status_gray {
    @extend %assignment_status;
    border: 1px solid #aaaaaa;
    color: #414141;
    background-color: #e1e1e1;
  }

  .assignment_actions_ipad {
    width: calc(100% - 20px);
    margin: 10px;
    align-items: center;
    grid-gap: 20px;
    grid-template-columns: max-content max-content max-content;

    p {
      display: flex;
      align-items: center;
      justify-content: center;
      margin: 0;
      word-break: break-word;
      hyphens: auto;
      padding: 5px;
      font-weight: normal;
    }
  }

  .assignment_actions_btn {
    position: relative;
    margin: 2px;
    width: 35px;
  }
  #assignment_name_header,
  .assignment_name_data {
    justify-self: left;
    text-align: left;
  }

  #assignment_name_header {
    padding-left: 32px;
  }
}

.show_on_ipad_zoom {
  display: none !important;
}

#assignment_filter_container {
  display: flex;
  flex-flow: row;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 10px;

  p {
    margin: 0;
  }

  #assignment_filter {
    width: 200px;
    margin-left: 10px;
  }
}

@media screen and (max-width: 1295px) {
  // iPad mode

  #assignment_table_container {
    [assignment-data-title]:after {
      left: 0px;
      right: unset;
      bottom: unset;
      top: -30px;
    }
  }

  %assignments_list_grid {
    grid-template-columns:
      160px 80px
      calc((100% - 604px)) 110px 110px
      65px 65px;
  }
}

@media screen and (max-width: 775px) {
  // mega zoom-in mode

  #assignment_filter_container {
    flex-flow: column;
  }

  .show_on_ipad_zoom {
    display: block !important;
    margin-right: 5px !important;
  }

  #assignments_list_header {
    display: none !important;
  }

  .assignment-name-and-triangle-grid {
    width: -webkit-fill-available;
    margin-top: 5px;
  }

  #assignment_table_container {
    .assignments_table_row {
      display: flex;
      flex-flow: wrap;
      grid-column-gap: 10px;

      .assignment_data_with_zoom_label {
        justify-content: flex-start;
      }
    }
  }
}
