.parental_consent_container {
  display: grid;
  width: 100%;
  grid-template-columns: 50% auto;
  grid-gap: 15px;
  align-items: center;
  margin-bottom: 15px;
}

.add_student_fields_container_row {
  display: grid;
  grid-gap: 0;
  grid-template-columns: 30% 30% 30%;
  margin-bottom: 15px;
}

.add_student_fields_schools {
  display: grid;
  grid-gap: 0;
  grid-template-columns: 59% 41%;
  margin-bottom: 15px;
}

.found_students_table_row {
  display: grid;
  grid-template-columns: 20% 25% 25% 20% auto;
  grid-gap: 5px;
  align-items: center;

  p {
    margin: 0;
    overflow-wrap: break-word;
  }
}

.existing_student_row {
  background-color: rgba(0, 0, 0, 0.05);
  border-top: 1px solid #dee2e6;
  padding: 5px 0 5px 0;
}

.student_name_flex_container {
  margin: 1px;

  span {
    height: -webkit-fill-available;
  }
}

.overflow_ellipsis {
  overflow: hidden;
  word-wrap: break-word;
  text-overflow: ellipsis;
}
