[data-title]:hover:after {
  opacity: 1;
  transition: all 0.1s ease 0.5s;
  visibility: visible;
}

[data-title]:after {
  content: attr(data-title);
  background-color: #2f353a;
  color: white;
  font-size: 120%;
  position: absolute;
  padding: 3px 6px;
  bottom: -1.6em;
  left: -90px !important;
  white-space: nowrap;
  box-shadow: 1px 1px 3px #222222;
  opacity: 0;
  border-radius: 5px;
  z-index: 99999;
  visibility: hidden;
}

.learner-reports[data-title]:after {
  left: -50px !important; /*exception for the learner reports btn onHover*/
}

[data-title] {
  position: relative;
}

.card,
.batch-row {
  margin-bottom: 2em;
}

.intro-vids {
  margin: 56px auto;
  display: block;
}

.form_icons {
  width: 100%;
  max-width: 250px;
}

.table-md td {
  padding: 0.5rem !important;
}

.headerSortDown:before,
.headerSortUp:before,
.headerSort:before {
  content: " ";
  position: relative;
  border: 7px solid transparent;
  height: fit-content;
}

.headerSortDown:before {
  border-top-color: #797979;
  top: 3.5px;
  margin-bottom: 2px;
}

.headerSortUp:before {
  border-left: 7px solid transparent;
  border-top: 7px solid transparent;
  border-right: 7px solid transparent;
  border-bottom: 7px solid #797979;
  margin-bottom: 5px;
}

.headerSort:before {
  content: "-";
  border: none;
  color: #797979;
  padding-left: 5px;
  padding-right: 5px;
}

%robots_table_row_shared {
  display: grid;
  min-width: 780px;
  grid-template-columns:
    20px 90px 90px 100px calc((100% - 800px) / 4) calc((100% - 800px) / 4) 130px 130px calc(
      (100% - 800px) / 4
    )
    calc((100% - 800px) / 4) 120px;
  grid-gap: 12px;

  div {
    display: flex;
    justify-content: center;
    align-items: center;

    .common_border {
      display: block;
      div {
        display: block;
        height: auto;
      }
    }
  }

  p {
    margin: 0;
  }
}

@mixin important {
  @each $property, $value in $style {
    #{$property}: $value !important;
  }
}
$style: (
  display: flex,
  -webkit-box-pack: start,
  justify-content: flex-start,
  -webkit-box-align: center,
  align-items: center,
  position: relative,
  text-decoration: none,
  min-height: 48px,
  padding-top: 6px,
  padding-bottom: 6px,
  box-sizing: border-box,
  white-space: nowrap,
  padding-left: 16px,
  padding-right: 16px,
);

.MuiMenuItem-root {
  @include important;
}

#filters_fields_robots_container {
  display: grid;
  align-items: baseline;
  grid-gap: 2%;
  grid-template-columns: 70px calc((90% - 170px) / 2) calc((90% - 170px) / 2) 100px;
}

#robots_table_hrs_row {
  @extend %robots_table_row_shared;
  font-weight: bold;
  padding: 10px 0px 10px 0px;
  grid-template-columns:
    20px 50px 80px 90px
    calc((100% - 710px) / 5) calc((100% - 710px) / 5) calc((100% - 710px) / 5)
    90px 130px 130px calc((100% - 710px) / 5)
    calc((100% - 710px) / 5) 120px;
  grid-gap: 0px;
  div {
    margin: 0px 5px 0px 5px;
  }
}

.robots_table_row {
  @extend %robots_table_row_shared;
  background-color: #f2f2f2;
  border-top: 1px solid rgba(0, 0, 0, 0.125);

  grid-template-columns:
    20px 50px 80px 90px
    calc((100% - 710px) / 5) calc((100% - 710px) / 5) calc((100% - 710px) / 5)
    90px 130px 130px calc((100% - 710px) / 5)
    calc((100% - 710px) / 5) 120px;
  grid-gap: 0px;
  p {
    padding: 10px 0px 10px 0px;
    text-overflow: clip;
    hyphens: auto;
  }
}

.robots_table_row_my_robots {
  @extend %robots_table_row_shared;
  background-color: #f2f2f2;
  grid-template-columns:
    20px 90px 90px 110px
    calc((100% - 505px) / 3) calc((100% - 505px) / 3) calc((100% - 505px) / 3)
    100px 95px;
  grid-gap: 0px;
  div {
    margin: 0px 5px 0px 5px;
  }
  border-top: 1px solid rgba(0, 0, 0, 0.125);
  p {
    padding: 10px 0px 10px 0px;
    text-overflow: clip;
    hyphens: auto;
  }
}

#robots_table_hrs_row_my_robots {
  @extend %robots_table_row_shared;
  grid-template-columns:
    20px 90px 90px 110px
    calc((100% - 505px) / 3) calc((100% - 505px) / 3) calc((100% - 505px) / 3)
    100px 95px;
  grid-gap: 0px;
  div {
    margin: 0px 5px 0px 5px;
  }
  p {
    padding: 10px 0px 10px 0px;
    text-overflow: clip;
    hyphens: auto;
    font-weight: bold;
  }
}

// Teachers page Robots section, near the top
%robots_table_row_manage_teacher_gridlayout {
  grid-template-columns:
    calc((100% - 90px) / 6) calc((100% - 90px) / 6)
    90px calc((100% - 90px) / 6)
    calc((100% - 90px) / 6) calc((100% - 90px) / 6) calc((100% - 90px) / 6);
  grid-gap: 0px;
}

.robots_table_row_manage_teacher {
  @extend %robots_table_row_shared;
  @extend %robots_table_row_manage_teacher_gridlayout;
  background-color: #f2f2f2;
  border-top: 1px solid rgba(0, 0, 0, 0.125);
  p {
    padding: 10px 0px 10px 0px;
    text-overflow: clip;
    hyphens: auto;
  }
}

#robots_table_hrs_row_manage_teacher {
  @extend %robots_table_row_shared;
  @extend %robots_table_row_manage_teacher_gridlayout;
  text-align: center;
  p {
    margin: 0px 10px 0px 10px;
    text-overflow: clip;
    hyphens: auto;
    font-weight: bold;
  }
}

.robots_table_row_super_admin {
  @extend %robots_table_row_shared;
  background-color: #f2f2f2;
  grid-gap: 10px;
  grid-template-columns:
    20px 60px 90px 80px
    calc((100% - 900px) / 3) calc((100% - 900px) / 3) calc((100% - 900px) / 3)
    100px
    100px 120px 70px
    70px 70px;
  border-top: 1px solid rgba(0, 0, 0, 0.125);
  min-width: 1100px;
  p {
    padding: 10px 0px 10px 0px;
    text-overflow: clip;
    hyphens: auto;
  }
}

#robots_table_hrs_row_super_admin {
  @extend %robots_table_row_shared;
  grid-gap: 10px;
  grid-template-columns:
    20px 60px 90px 80px
    calc((100% - 900px) / 3) calc((100% - 900px) / 3) calc((100% - 900px) / 3)
    100px
    100px 120px 70px
    70px 70px;
  min-width: 1100px;
  p {
    padding: 10px 0px 10px 0px;
    text-overflow: clip;
    hyphens: auto;
    font-weight: bold;
  }
}

.pdf-upload-wrapper {
  display: block;
  height: fit-content !important;

  > div:not(.multi-school-select) {
    height: fit-content;
    display: block;
  }

  p {
    padding: 0;
  }
}

.pdf-upload-wrapper.common_dashboard_bg {
  display: flex;
}

.pdf_popup_close {
  height: fit-content;
}

.common_heading {
  padding: 15px;
}

.robot_actions_show_on_ipad,
.robot_actions_show_on_ipad_super_admin {
  display: none !important;
  p {
    display: none;
  }
}

.show_on_ipad_hr {
  font-weight: bold;
  margin-bottom: 0px;
}

.robot_show_on_ipad_container {
  width: 100%;
  padding-left: 10px;
}

@media screen and (max-width: 1295px) {
  %robots_table_row_shared {
    grid-template-columns:
      20px 90px 90px calc((100% - 535px) / 2) calc((100% - 535px) / 2)
      90px 85px 80px;
    grid-gap: 10px;
    border: none;
  }

  .robot_actions_show_on_ipad {
    display: grid !important;
    grid-template-columns: 200px 200px 120px;
    background-color: #f2f2f2;
    border-bottom: 1px solid rgba(0, 0, 0, 0.125);
    min-width: 780px;
    p {
      display: block !important;
    }
    div {
      display: block !important;
    }
    button {
      display: inline-block !important;
    }
  }

  #robots_table_hrs_row_super_admin,
  .robots_table_row_super_admin {
    border-top: 1px solid rgba(0, 0, 0, 0.125);
    grid-template-columns:
      20px 60px 90px 80px
      calc((100% - 900px) / 3) calc((100% - 900px) / 3) calc((100% - 900px) / 3)
      100px
      100px 120px 70px
      70px 70px;
  }

  // Teachers page Robots section, near the top
  .robots_table_row_manage_teacher,
  #robots_table_hrs_row_manage_teacher {
    grid-template-columns:
      calc((100% - 90px) / 6) calc((100% - 90px) / 6)
      90px calc((100% - 90px) / 6)
      calc((100% - 90px) / 6) calc((100% - 90px) / 6) calc((100% - 90px) / 6);
    grid-gap: 0px;
    min-width: unset;
  }

  .robots_table_row_my_robots,
  #robots_table_hrs_row_my_robots {
    grid-template-columns:
      20px 90px 90px 110px
      calc((100% - 505px) / 3) calc((100% - 505px) / 3) calc((100% - 505px) / 3)
      100px 95px;
    grid-gap: 0px;
    div {
      margin: 0px 5px 0px 5px;
    }
  }

  .robots_table_row {
    grid-template-columns:
      20px 60px 80px 90px
      calc((100% - 545px) / 3) calc((100% - 545px) / 3) calc((100% - 545px) / 3)
      90px 90px 115px;
    grid-gap: 0px;
  }

  #robots_table_hrs_row {
    grid-template-columns:
      20px 60px 80px 90px
      calc((100% - 545px) / 3) calc((100% - 545px) / 3) calc((100% - 545px) / 3)
      90px 90px 115px;
    grid-gap: 0px;
  }

  .hide_on_ipad {
    display: none;
    p {
      display: none;
    }
    button {
      display: none;
    }
  }
}

.robot_summary_stats_and_filter {
  display: inline-flex;
  flex-wrap: wrap;
  flex-flow: wrap;
  align-items: baseline;
  width: 100%;
  text-align: center;
  border-top: 1px solid #dee2e6;
  border-bottom: 2px solid #dee2e6;
  font-weight: bold;
  div {
    padding: 0.25rem;
    flex: 1;
    min-width: fit-content;
  }
  p {
    display: inline-flex;
    margin-bottom: 2px;
    padding: 4px;
    align-items: baseline;
  }
}
