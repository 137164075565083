.usage_stats_row_container {
  background-color: #fff;
  width: 100%;
}

.usage_stats_status_container,
.usage_stats_graph_container {
  border: 1px solid #c4c4c4;
}

.usage_stats_row_hr {
  width: 100%;
  margin: 0px;
  padding: 10px;
  background-color: #f1f3f5;
  border-bottom: 1px solid #c4c4c4;
  font-weight: bold;
}

.usage_stats_status_container {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.usage_stats_graph_container {
  height: inherit;
}

.avg_caption {
  max-width: calc(100% - 20px);
  font-size: 120%;
  padding: 0.5rem 0.5rem !important;
  margin: 10px;
  word-wrap: break-word;
  hyphens: none !important;
}

.caption_btn {
  margin: 10px;
  max-height: fit-content;
  justify-self: flex-end;
}

#stats_grid {
  display: grid;
  grid-template-columns: 480px calc(100% - 485px);
  grid-gap: 10px;
  background-color: #f2f2f2;
  padding: 10px;
}

.impact_stats_grid {
  display: grid;
  grid-template-rows: 40px 40px auto;
  grid-template-columns: 300px calc(100% - 300px);
  background-color: #f2f2f2;
  padding: 10px;
}

.whole-row {
  grid-column: 1 / -1;
}

#pie_container {
  justify-content: center;
  margin: auto;
  display: flex;
  flex-flow: wrap;
  place-self: flex-start;
  min-height: 360px;
}

#circles_container {
  display: flex;
  flex-flow: wrap;
  justify-content: center;
  place-self: flex-start;
  margin: auto;
}

.circle_container {
  display: flex;
  flex-flow: column;
  align-items: center;
  height: fit-content;
  width: 220px;
  margin: 5px;
  text-align: center;

  span {
    font-size: 20px;
    font-weight: bold;
  }
}

.stats_circle {
  width: 170px;
  height: 170px;
  border-radius: 50%;
  cursor: pointer;
  font-size: 50px;
  font-weight: bold;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: box-shadow 0.3s ease;
}

.circle-red {
  border: 5px solid #cb444a;
  //background-color: #f3d8da;
  color: #cb444a;
}

.circle-yellow {
  border: 5px solid #b6a402;
  //background-color: #fdf2d1;
  color: #806420;
}

.circle-green {
  border: 5px solid #377d22;
  //background-color: #d9ecdb;
  color: #377d22;
}

.circle-red-active {
  box-shadow: 0px 0px 15px #cb444a;
}

.circle-yellow-active {
  box-shadow: 0px 0px 15px #b6a402;
}

.circle-green-active {
  box-shadow: 0px 0px 15px #377d22;
}

.circle-gray {
  border: 5px solid #808080;
  background-color: #dfe2e6;
  color: #808080;
  font-size: 30px;
}

#popup_chart_grid {
  height: 45vh;
  width: 100%;
  max-height: 500px;
  min-height: 300px;
  display: grid;
  grid-template-columns: 100%;
  grid-template-rows: calc(100% - 40px) 40px;
  overflow-x: auto;
}

#impact_stats_chart_popup {
  max-width: 80%;
}

.learner-impact-stats {
  border-top: 2px solid rgba(0, 0, 0, 0.2);
  .carousel_container {
    height: 450px;
    width: 100%;
    margin: 5px;
  }

  .carousel_btns_container {
    display: flex;
    flex-flow: row;
    justify-content: center;
    width: 100%;
  }

  .carousel_btn {
    width: 20px;
    height: 20px;
    background-color: #40aae3;
    border-radius: 50%;
    border: 2px solid #40aae3;
    margin: 5px;
    cursor: pointer;
  }

  .carousel_btn.unselected {
    background-color: white;
    border: 2px solid #7e7e7e;
  }

  .carousel_chart {
    position: absolute;
    transition: left 0.5s;
    width: 100%;
    height: 100%;
  }

  .carousel_charts_wrapper {
    justify-content: center;
    height: 420px;
    display: flex;
  }

  .summary-and-chart {
    width: 100%;
    display: grid;
    grid-template-rows: 40px auto;
    row-gap: 10px;
  }

  .impact_stats_grid {
    background-color: #fff;
    grid-template-rows: 40px 40px auto;
  }

  #pie_container {
    min-height: 320px;
  }

  .empty-stats-text {
    display: flex;
    justify-content: center;
    align-content: center;
    flex-direction: column;
    text-align: center;
    font-weight: bold;
    height: 100%;
  }
}

@media screen and (max-width: 1170px) {
  #stats_grid,
  .impact_stats_grid {
    grid-template-columns: 100%;
    grid-template-rows: min-content 400px;
  }
  .impact_stats_grid {
    display: flex;
    flex-flow: column;
  }

  .learner-impact-stats {
    #pie_container {
      margin-bottom: 20px;
    }
    .carousel_container {
      height: 800px;
    }
    .carousel_charts_wrapper {
      height: 750px;
    }
  }

  #impact_stats_chart_popup {
    max-width: 100%;
  }
}
