#edit_assignment_modal_fields_grid {
  display: flex;
  flex-flow: column;
  width: 100%;
  max-width: 700px;
  align-items: center;
  grid-template-areas:
    "a"
    "c"
    "d";
  grid-gap: 0px;
  margin: auto auto 20px auto;

  #close_assignment_checkbox_container {
    display: flex;
    flex-flow: column;
    margin: auto;
    align-items: center;

    p {
      margin: 0;
    }
  }
}

.edit_assignment_field_container {
  display: flex;
  flex-flow: column;
  margin-bottom: 20px;
  max-width: 300px;
}
.edit_assignment_field_container_caption {
  margin: 0px 0px 5px 0px;
  font-weight: bold;
}
#edit_assignment_timeline_container {
  display: grid;
  grid-template-areas:
    "a b c"
    "d e f";
  grid-template-columns: calc(49% - 20px) auto calc(49% - 20px);
  grid-gap: 10px;

  .timeframe_caption {
    margin-bottom: -10px;
  }

  #edit_assignment_timeline_arrow {
    position: relative;
    display: flex;
    align-items: center;
    height: 20px;
    margin-top: 40px;

    hr {
      border: 1px solid #a2a2a2;
      margin: 0;
      flex: 1;
      float: left;
    }

    p {
      margin: 0;
    }

    .arrow {
      float: right;
      margin-left: -7px;
      border: solid #a2a2a2;
      border-width: 0 3px 3px 0;
      display: inline-block;
      padding: 3px;
    }

    .right {
      transform: rotate(-45deg);
      -webkit-transform: rotate(-45deg);
    }
  }
}
