div.showPW {
  display: flex;
  border: 1px solid #ced4da;
  border-radius: 5px;
  overflow: hidden;
}

div.showPW button {
  padding: 0;
  color: #fff;
  margin: 0;
  width: 50px;
  background: #e4e5e6;
  display: flex;
  justify-content: center;
  align-items: center;
}

[data-title]:hover:after {
  opacity: 1;
  transition: all 0.1s ease 0.5s;
  visibility: visible;
}

[data-title]:after {
  content: attr(data-title);
  background-color: #2f353a;
  color: white;
  font-size: 120%;
  position: absolute;
  padding: 3px 6px;
  bottom: -1.6em;
  left: -50px;
  white-space: nowrap;
  box-shadow: 1px 1px 3px #222222;
  opacity: 0;
  border-radius: 5px;
  z-index: 99999;
  visibility: hidden;
}

[data-title] {
  position: relative;
}

.btn.disabled {
  opacity: 0.6;
  cursor: not-allowed;
}

.disabled-btn {
  background-color: darkgrey;
  color: #fff;
}

.disabled-btn:hover {
  background-color: darkgrey;
}

.btn:disabled {
  cursor: not-allowed;
  opacity: 0.6;
}

.btn:disabled:hover {
  z-index: 20;
  opacity: 0.6;
}
