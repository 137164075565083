#scores_table_container {
  overflow-x: auto;
  border: 1px solid rgba(0, 0, 0, 0.2);
}

#learner_scores_table {
  margin: 5px;
  border-radius: 10px;
}

#scores_table_header.has-data {
  border-left: 1px solid rgba(0, 0, 0, 0.2);
  border-top: 1px solid rgba(0, 0, 0, 0.2);
  border-right: 1px solid rgba(0, 0, 0, 0.2);
  display: grid;
  grid-template-columns: calc((100% - 570px) / 2) 570px calc((100% - 570px) / 2);
  grid-template-areas: "a b c";
  align-items: center;
  justify-content: center;
  padding: 5px;
  width: 100%;
}

#pdf_class_btn_container {
  grid-area: c;
  justify-self: end;
  flex: 1;
}

#class_scores_subject_label {
  grid-area: "a";
  margin: 0px;
}

.actions_container {
  position: relative;
  min-height: 35px;
}

.actions_reports_btn {
  position: absolute;
  padding: 10px;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  width: 30px;
  height: 30px;
}

.actions_reports_btn:hover {
  z-index: 2;
}

#standards_switch_container {
  width: fit-content;
}

#standards_switch_sort_container {
  display: flex;
  flex-flow: row;
  align-items: center;
  justify-content: center;

  label {
    margin: 0px 5px 0px 5px;
  }
}

@media screen and (max-width: 1200px) {
  #scores_table_header.has-data {
    grid-gap: 5px;
    grid-template-columns: none;
    grid-template-rows: auto auto;
    grid-template-areas:
      "a"
      "b"
      "c";

    #standards_switch_container {
      margin: auto;
    }
  }

  #class_scores_subject_label {
    align-self: center;
    justify-self: center;
  }

  #pdf_class_btn_container {
    align-self: center;
    justify-self: center;
  }
}

#learner_scores_table_container {
  margin-top: 10px;
  grid-area: c;
  padding: 5px;
  width: 100%;
  border-top: 2px solid rgba(0, 0, 0, 0.2);
}

#default_table_container {
  width: 100%;
}

#default_table {
  border: 1px solid rgba(0, 0, 0, 0.2);
}

.no_lessons_available {
  align-items: end;
  border-right: 1px solid rgba(0, 0, 0, 0.2);
}

.default_table_labels_row {
  grid-template-columns: 160px calc(100% - 300px) 70px 70px;
}

.default_table_avg_label {
  border-left: 1px solid rgba(0, 0, 0, 0.2);
}

.default_table_msg {
  text-align: center;
  padding: 5px;
  margin: 0px;
  background-color: rgba(0, 0, 0, 0.1);
}

%table_borders {
  border-right: 1px solid rgba(0, 0, 0, 0.2);
  border-bottom: 1px solid rgba(0, 0, 0, 0.2);

  border-left: 1px solid transparent;
}

.sortable {
  cursor: pointer;
}

.headerSortDown:before,
.headerSortUp:before,
.headerSort:before {
  content: " ";
  position: relative;
  border: 7px solid transparent;
  height: fit-content;
}

.headerSortDown:before {
  border-top-color: #797979;
  top: 3.5px;
  margin-bottom: 2px;
}

.headerSortUp:before {
  border-left: 7px solid transparent;
  border-top: 7px solid transparent;
  border-right: 7px solid transparent;
  border-bottom: 7px solid #797979;
  margin-bottom: 5px;
}

.headerSort:before {
  content: "-";
  border: none;
  color: #797979;
  padding-left: 5px;
  padding-right: 5px;
}

.hover_lesson_header_info {
  position: absolute;
}

#learner_scores_rows_container {
  .labels_row {
    align-items: flex-end;
    height: 200px;
    font-weight: bold;
  }

  .reports_row,
  .labels_row {
    display: grid;
    align-items: center;
    grid-auto-flow: column;
    min-height: fit-content;

    p {
      margin: 0;
      text-overflow: clip;
      word-wrap: break-word;
      overflow: hidden;
    }

    .score_hr {
      border-top: 1px solid #adadad;
      margin: auto;
      width: 100%;
    }

    div {
      height: 100%;
      display: flex;
      justify-content: center;
    }

    .data {
      align-items: center;
    }

    .learner_averages {
      border-left: 1px solid rgba(0, 0, 0, 0.2);
      margin-left: -1px;
    }

    .data.score {
      border-top: 1px solid #adadad;
    }

    .row_averages {
      border-top: none !important;
      padding-top: 1px;
    }

    .name {
      cursor: pointer;
      width: 160px;
      padding-left: 5px;
      padding-right: 5px;
      justify-content: left;
    }

    .lesson_avg_label {
      border-right: 1px solid rgba(0, 0, 0, 0.2);
      height: 100%;
    }

    .score,
    .dash {
      border-right: 1px solid rgba(0, 0, 0, 0.2);
    }

    .average_label,
    .learners_label {
      align-items: flex-end;
      border-right: 1px solid rgba(0, 0, 0, 0.2);
    }

    .learners_label {
      justify-content: left;
    }

    .label_container {
      display: grid;
      grid-template-rows: 180px 20px;
      grid-template-columns: 30px;
      border-right: 1px solid rgba(0, 0, 0, 0.2);
      cursor: pointer;
    }

    .label_container:hover,
    .name:hover,
    .average_label:hover {
      background-color: rgba(0, 0, 0, 0.1);
    }

    .label_container[data-title] {
      position: relative;
    }

    .label_container[data-title]:hover::after {
      content: attr(data-title);
      position: absolute;
      white-space: pre-wrap;
      width: 400px;
    }

    .label {
      white-space: nowrap;
      display: flex;
      text-overflow: ellipsis;
      height: fit-content;
      width: 180px;
      justify-self: center;
      align-self: center;
      transform: rotate(270deg);
    }

    .actions {
      align-items: center;
      width: 70px;
      text-align: center;
    }

    .standards_showing_true {
      display: flex;
      margin-left: 5px;
      font-size: 90%;
      color: #5a5a5a;
    }
  }
}
