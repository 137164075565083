[data-title]:hover:after {
  opacity: 1;
  transition: all 0.1s ease 0.5s;
  visibility: visible;
}

[data-title]:after {
  content: attr(data-title);
  background-color: #2f353a;
  color: white;
  font-size: 120%;
  position: absolute;
  padding: 3px 6px;
  bottom: -1.6em;
  left: -90px !important;
  white-space: nowrap;
  box-shadow: 1px 1px 3px #222222;
  opacity: 0;
  border-radius: 5px;
  z-index: 99999;
  visibility: hidden;
}

.learner-reports[data-title]:after {
  left: -50px !important; /*exception for the learner reports btn onHover*/
}

[data-title] {
  position: relative;
}

.card,
.batch-row {
  margin-bottom: 2em;
}

.intro-vids {
  margin: 56px auto;
  display: block;
}

.form_icons {
  width: 100%;
  max-width: 250px;
}

.pdf-upolad-wrapper {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 100%;
  max-width: 565px;
}

.common_padding_space {
  padding: 1rem 1rem 1rem !important;
  overflow: hidden !important;
}

%class-grid-row-shared {
  display: grid;
  grid-template-columns:
    20px calc((100% - 900px) / 2) calc((100% - 900px) / 2)
    100px 90px
    60px
    110px 100px 100px 95px 130px;
  padding: 0px 10px 0px 10px;
  grid-gap: 10px;
  border-bottom: 1px solid rgba(0, 0, 0, 0.125);

  p {
    display: flex;
    align-items: center;
    margin: 0;
    padding: 10px;
  }

  span {
    display: flex;
    align-items: center;
  }
}

.show_on_ipad {
  display: none;
}

#learner_username_hr {
  justify-self: left;
  align-items: center;

  p {
    padding: 10px 10px 10px 0px;
    display: block;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }
}

.class-grid-row-caption-container {
  @extend %class-grid-row-shared;
  font-weight: bold;
  align-items: center;
  p,
  span,
  div {
    height: fit-content;
  }

  span {
    text-align: center;
  }
}

.class-grid-row-fields-container {
  @extend %class-grid-row-shared;
  background-color: #f2f2f2;

  span {
    margin: auto 0 auto;
    text-align: center;
  }
}

.hide_on_ipad {
  display: flex;
}

.edit_info_fields {
  padding: 10px;
  display: flex;
  flex-wrap: wrap;
}

.edit_info_fields > * {
  flex: 1 1 50%;
  padding: 5px !important;
}

.edit_info_buttons {
  display: flex;
}

.edit_info_buttons > * {
  flex: 1 1 33%;
  padding: 5px !important;
}

.common_dashboard_bg {
  padding: 25px !important;
}

@media only screen and (max-width: 1295px) {
  %class-grid-row-shared {
    grid-template-columns:
      20px calc((100% - 630px) / 2) calc((100% - 630px) / 2)
      90px 85px
      60px 105px 100px 100px;
  }

  .common_dashboard_bg {
    padding: 25px !important;
  }

  .hide_on_ipad {
    display: none !important;
  }

  .show_on_ipad {
    display: grid;
    grid-gap: 20px;
    grid-template-columns: auto auto;
    min-width: 150px;
    align-items: center;
    margin-bottom: 10px;
    text-align: left;
    font-weight: bold;
  }

  .common_dashboard_bg {
    padding: 25px;
  }
}

.student_list_row {
  display: grid;
  grid-template-columns:
    calc(100% / 9) calc(100% / 9) calc(100% / 9) calc(100% / 9)
    calc(100% / 9) calc(100% / 9) calc(100% / 9) calc(100% / 9) calc(100% / 9);
  padding: 10px;
  border-bottom: 1px solid rgba(0, 0, 0, 0.125);
}

%autosave {
  display: inline-flex;
  align-items: center;
  position: fixed;
  min-width: 170px;
  background-color: white;
  border: 1px solid #d0e7d2;
  border-radius: 5px;
  bottom: 5px;
  right: 5px;
  z-index: 1;
  font-weight: bold;
  font-size: 110%;
  padding: 10px;

  p {
    margin: 0px 0px 0px 5px;
  }
}

#autosave-false {
  @extend %autosave;
  opacity: 0;
  transition: opacity 1s linear 2s;
}

#autosave-true {
  @extend %autosave;
}

.autosave-caption-null,
.autosave-caption-true {
  color: #4d734d;
}

.autosave-caption-false {
  color: #cb444a;
}

#learners-wrapper {
  padding-top: 24px;
  padding-bottom: 150px !important;
}

.filter_checkboxes_container {
  display: flex;
  flex-flow: column;
  P {
    margin: 0;
    padding: 0;
  }
}

#filters_fields_all_learners_container {
  display: grid;
  align-items: baseline;
  grid-gap: 2%;
  grid-template-columns:
    95px calc((92% - 220px) / 4) calc((92% - 220px) / 4)
    calc((92% - 220px) / 4) calc((92% - 220px) / 4) 100px;
}

#filter_reset_btn_show_mobile {
  display: none;
}

@media screen and (max-width: 800px) {
  #filters_fields_all_learners_container {
    grid-gap: 1%;
    grid-template-columns:
      100px calc((97% - 100px) / 4) calc((97% - 100px) / 4)
      calc((97% - 100px) / 4) calc((97% - 100px) / 4);
  }

  .filter_checkboxes_container {
    display: flex;
    flex-flow: column;
    P {
      margin: 0;
      padding: 0;
    }
  }

  #filter_reset_btn_show_mobile {
    display: block;
  }

  #filter_reset_btn_hide_mobile {
    display: none;
  }
}

@media screen and (max-width: 400px) {
  .edit_info_fields > * {
    flex: 1 1 100%;
    padding: 5px !important;
  }

  .edit_info_buttons > * {
    flex: 1 1 100%;
    padding: 5px !important;
  }
}
