.create_edit_fields_grid {
  display: grid;
  grid-template-columns: 50% 50%;
  margin-left: 10px;
  margin-right: 10px;
}

.org_form_icons label {
  margin-bottom: 0px;
}
#saving-msg-false,
#saving-msg-true,
#deleting-msg-false,
#deleting-msg-true {
  margin-bottom: 0px;
  padding: 0.5rem 1rem;
  p {
    margin-bottom: 0px;
  }
}

#saving-msg-false,
#deleting-msg-false {
  opacity: 0;
  transition: opacity 1s linear 2s;
}

#make_contact_popup_whitebox,
#delete_popup_whitebox {
  max-height: calc(100% - 100px - 67px);
  display: grid;
  grid-template-rows: auto auto max-content;
}

#edit_contact_fields_container {
  overflow: auto;
}
