#learner_over_time_container {
  border-top: 2px solid rgba(0, 0, 0, 0.2);
  margin-top: 20px;

  h4 {
    margin: 15px 0px 15px 0px;
  }
}

#learner_over_time_chart_container {
  height: 200px;
  background-color: rgba(0, 0, 0, 0.2);
  display: flex;
  padding: 20px;
  position: relative;
  align-items: center;
  justify-content: center;

  p {
    position: absolute;
  }
}

.chartWrapper {
  position: relative;
}

.chartWrapper > canvas {
  position: absolute;
  left: 0;
  top: 0;
  pointer-events: none;
}

.chartAreaWrapper {
  overflow-x: scroll;
}

.cls-1 {
  fill: #666;
}
.cls-1,
.cls-2 {
  stroke: #666;
  stroke-miterlimit: 10;
}
.cls-2 {
  fill: none;
  stroke-width: 0.5px;
}
