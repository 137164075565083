#user_search_bar_container {
  display: flex;
  align-items: center;
  max-width: 100%;

  #user_search_bar {
    max-width: 300px;
  }

  .alert {
    margin: 0px 0px 0px 15px;
  }
}

#add_user_to_subscription_wrapper,
#add_robot_to_subscription_btns_container {
  max-width: 1180px;
  max-height: 100vh;
  display: grid;
  grid-template-rows: 57px calc(100% - 57px);
}

#user_search_common_dashboard_bg,
#robot_search_common_dashboard_bg {
  max-height: calc(100vh - 57px);
  display: grid;
}

#user_search_common_dashboard_bg {
  grid-template-rows: 40px calc(100% - 90px) 50px;
}

#robot_search_common_dashboard_bg {
  grid-template-rows: 1fr;
}

%user_search_gridlayout {
  display: grid;
  grid-template-columns:
    calc((100% - 210px) / 3) calc((100% - 210px) / 3) 140px calc(
      (100% - 210px) / 3
    )
    50px;
  padding: 5px;
  grid-gap: 5px;
  align-items: center;

  p {
    margin: 0;
  }
}

#user_search_hr {
  @extend %user_search_gridlayout;
  font-weight: bold;
}

.user_search_option {
  @extend %user_search_gridlayout;
  border-bottom: 1px solid #c8ced3;
  cursor: pointer;
  background-color: rgba(0, 0, 0, 0.05);
}

#user_search_results_container,
#robot_search_results_container {
  display: grid;
  grid-template-rows: auto auto;
  overflow-y: auto;
  max-height: 100%;
}

#robot_search_results_container {
  height: 100%;
}

#user_search_results_table {
  overflow-y: auto;
  overflow-x: hidden;
}

#add_user_to_subscription_btns_container {
  display: flex;
  grid-gap: 15px;
  justify-content: center;
  height: fit-content;
  margin: auto;
}
