div.showPW {
  display: flex;
  border: 1px solid #ced4da;
  border-radius: 5px;
  overflow: hidden;
}

div.showPW button {
  padding: 0;
  color: #fff;
  margin: 0;
  width: 50px;
  background: #e4e5e6;
  display: flex;
  justify-content: center;
  align-items: center;
}

.disabled-btn {
  background-color: darkgrey;
  color: #fff;
}

.disabled-btn:hover {
  background-color: darkgrey;
}

#learner_avg_lessons_hr {
  justify-self: left;
  align-items: center;
  width: -webkit-fill-available;
  p {
    padding: 10px 10px 10px 0px;
    display: -webkit-inline-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
  }
}
