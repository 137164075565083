.manage_teachers_field_container {
  display: inline-flex;
  width: 100%;
  flex-wrap: wrap;
  align-items: flex-end;
}

.admin_settings_box {
  margin-right: 2%;
}

.admin_settings_btns_container {
  width: 100%;
  display: inline-flex;
}

.admin_settings_hr {
  width: 100%;
  margin-top: 0;
}

.admin_settings_err_msg {
  color: red;
}

.td_buttons {
  display: grid;
  grid-template-columns: 40px 40px 40px 40px 40px 40px;
  grid-template-rows: 35px;
  margin-left: 10px;
}

.admin_settings_btn {
  margin-left: 5px;
}

#users_table_rows_container {
  min-width: 700px;
}

.users_table {
  display: grid;
  //grid-gap: 1%;
  justify-content: center;
  grid-template-columns:
    calc((100% - 640px) / 3) calc((100% - 640px) / 3)
    calc((100% - 640px) / 3) 60px 60px 110px 100px 50px 240px;
  text-align: center;
  p {
    margin: 0;
    padding: 0;
    width: fit-content;
  }

  div {
    display: flex;
    padding: 10px;
    align-items: center;
    span {
      word-break: break-word;
    }
  }
}

.users_table_headers {
  font-weight: bold;
  p {
    display: flex;
    align-items: center;
  }
}

.users_table_row {
  border-bottom: 1px solid rgba(0, 0, 0, 0.125);
}

.gray_row {
  background-color: #f2f2f2;
}

#filters_fields_users_container {
  display: grid;
  align-items: baseline;
  grid-gap: 2%;
  grid-template-columns:
    70px calc((90% - 170px) / 4) calc((90% - 170px) / 4) calc((90% - 170px) / 4)
    calc((90% - 170px) / 4) 100px;
}

.admin_access_filter {
  display: flex;
  flex-flow: row;
  align-items: center;

  p {
    font-size: 110%;
    margin: 0px 5px 0px 0px;
    padding: 0px;
  }
}

@media screen and (max-width: 1100px) {
  .actions_label {
    display: none !important;
  }

  #filters_fields_users_container {
    grid-template-columns: calc(90% / 4) calc(90% / 4) calc(90% / 4) calc(
        90% / 4
      );
  }

  .users_table {
    grid-template-columns:
      calc((93% - 400px) / 3) calc((93% - 400px) / 3)
      calc((93% - 400px) / 3) 60px 60px 110px 100px 50px;
  }

  .td_buttons {
    padding: 0px 0px 10px 0px !important;
  }
}
