#learner_by_session_container {
  margin-top: 15px;
  border-top: 2px solid rgba(0, 0, 0, 0.2);

  h4 {
    margin: 15px 0px 15px 0px;
  }
}

#learner_session_options {
  display: grid;
  grid-template-columns: 50% 50%;
}
