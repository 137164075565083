.small_popup_gray_backdrop {
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background-color: rgba(228, 229, 230, 0.5);
  z-index: 1040;
}

.small_popup_wrapper {
  display: block;
  height: fit-content;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 100%;
  max-width: 565px;
  z-index: 1050;
}
