.status-text {
  grid-area: b;
  text-align: left;
  min-width: 10%;
}
.status-button {
  grid-area: a;
  text-align: center;
}
.status-blank {
  grid-area: c;
  min-width: 10%;
}

.status-container {
  display: grid;
  grid-template-columns: auto 70% 10%;
  grid-template-areas: "a b c";
  grid-gap: 10px;
  //padding: 5px;
  margin-bottom: 10px;
}

.status-container > div {
  //text-align: center;
}
/* Default styles for larger screens */
.teacherDropdown.form-control.input-underline {
  width: 80vh;
}

.schoolDropdown.multi-school-select {
  width: 80vh;
}

/* Responsive styles for smaller screens */
@media (max-width: 1884px) {
  .teacherDropdown.form-control.input-underline {
    width: 60vh;
  }

  .schoolDropdown.multi-school-select {
    width: 60vh;
  }
}

@media (max-width: 1615px) {
  .teacherDropdown.form-control.input-underline {
    width: 40vh;
  }

  .schoolDropdown.multi-school-select {
    width: 40vh;
  }
}

@media (max-width: 1240px) {
  .teacherDropdown.form-control.input-underline {
    width: 30vh;
  }

  .schoolDropdown.multi-school-select {
    width: 30vh;
  }
}

@media (max-width: 1060px) {
  .teacherDropdown.form-control.input-underline {
    width: 20vh;
  }

  .schoolDropdown.multi-school-select {
    width: 20vh;
  }
}
@media (max-width: 696px) {
  .teacherDropdown.form-control.input-underline {
    width: 10vh;
  }

  .schoolDropdown.multi-school-select {
    width: 10vh;
  }
}
@media (max-width: 450px) {
  .teacherDropdown.form-control.input-underline {
    width: 5vh;
  }

  .schoolDropdown.multi-school-select {
    width: 5vh;
  }
}
