// -------------- action btn hover text ---------------

%action-btn-on-hover-txt {
  background-color: #2f353a;
  color: white;
  font-size: 120%;
  position: absolute;
  padding: 3px 6px;
  bottom: -1.6em;
  white-space: nowrap;
  box-shadow: 1px 1px 3px #222222;
  opacity: 0;
  border-radius: 5px;
  z-index: 99999;
  visibility: hidden;
}

[subscription-data-title]:after {
  @extend %action-btn-on-hover-txt;
  content: attr(subscription-data-title);
  left: unset;
  right: 0px;
  bottom: unset;
  top: -30px;
}

[subscription-data-title]:hover:after {
  opacity: 1;
  transition: all 0.1s ease 0.5s;
  visibility: visible;
}

// --------------------------

#abii-content-packages-hr {
  width: 100%;
  margin-left: 0;
  padding-top: 1rem;
  padding-bottom: 1rem;
}

.subscription-type {
  display: inline-block;
}

.subscription-info {
  display: grid;
  grid-template-columns:
    140px
    300px;
  margin-bottom: 8px;

  div {
    margin: 3px 7px 2px 7px;
    hyphens: auto;
  }
}

%subscription_row {
  display: grid;
  grid-template-columns:
    150px
    calc((100% - 150px) / 5) calc((100% - 150px) / 5)
    calc((100% - 150px) / 5) calc((100% - 150px) / 5)
    calc((100% - 150px) / 5);

  div {
    margin: 3px 7px 2px 7px;
    hyphens: auto;
  }

  p {
    margin-bottom: 8px;
  }

  .date-picker div {
    margin: auto 10px auto 0;
  }
}

%subscription_row_super_admin {
  display: grid;
  grid-template-columns:
    150px
    calc((100% - 150px) / 6) calc((100% - 150px) / 6)
    calc((100% - 150px) / 6) calc((100% - 150px) / 6)
    calc((100% - 150px) / 6) calc((100% - 150px) / 6);

  div {
    margin: 3px 7px 2px 7px;
    hyphens: auto;
  }

  p {
    margin-bottom: 8px;
  }

  .date-picker div {
    margin: auto 10px auto 0;
  }
}

%subscription_row_renewal {
  display: grid;
  grid-template-columns:
    60px calc((100% - 300px) / 3)
    70px calc((100% - 300px) / 3) 90px calc((100% - 300px) / 3)
    70px;

  div {
    margin: 5px;
    hyphens: auto;
  }

  .date-picker div {
    margin: auto 10px auto 0;
  }

  p {
    margin-bottom: 6px;
  }
}

#subscription_row_table_all {
  overflow: auto;
}

%subscription_row_all {
  display: grid;
  grid-template-columns:
    calc((100% - 480px) / 5)
    calc((100% - 480px) / 4)
    100px
    calc((100% - 480px) / 4)
    calc((100% - 480px) / 5)
    calc((100% - 480px) / 6) 80px
    100px
    110px;
  min-width: 780px;

  div {
    margin: 1px 7px 1px 7px;
    hyphens: auto;
  }

  p {
    margin-bottom: 8px;
  }

  .date-picker div {
    margin: auto 10px auto 0;
  }
}

.subscription_row_hr {
  @extend %subscription_row;
  border-bottom: 2px solid rgba(0, 0, 0, 0.2);
  border-top: 1px solid rgba(0, 0, 0, 0.2);
  div {
    margin: 7px;
    font-weight: bold;
  }
  align-items: center;
}

.super_admin_subscription_row_hr {
  @extend %subscription_row_super_admin;
  border-bottom: 2px solid rgba(0, 0, 0, 0.2);
  border-top: 1px solid rgba(0, 0, 0, 0.2);
  div {
    margin: 7px;
    font-weight: bold;
  }
  align-items: center;
}

.subscription_row_data {
  @extend %subscription_row;
  border-top: 1px solid rgba(0, 0, 0, 0.125);

  [data-title]:after {
    left: 20px !important;
  }

  [subscriptions-data-title]:after {
    right: 0px !important;
  }
}

.super_admin_subscription_row_data {
  @extend %subscription_row_super_admin;
  border-top: 1px solid rgba(0, 0, 0, 0.125);

  [data-title]:after {
    left: 20px !important;
  }

  [subscriptions-data-title]:after {
    right: 0px !important;
  }
}

.subscription_row_hr_all {
  @extend %subscription_row_all;
  border-bottom: 2px solid rgba(0, 0, 0, 0.2);
  border-top: 1px solid rgba(0, 0, 0, 0.2);
  div {
    margin: 7px;
    font-weight: bold;
  }
  align-items: center;
}

.subscription_row_data_all {
  @extend %subscription_row_all;
  border-top: 1px solid rgba(0, 0, 0, 0.125);

  [data-title]:after {
    left: 20px !important;
  }

  [subscriptions-data-title]:after {
    right: 0px !important;
  }
}

.subscription_row_renewal_hr {
  @extend %subscription_row_renewal;
  border-bottom: 2px solid rgba(0, 0, 0, 0.2);
  border-top: 1px solid rgba(0, 0, 0, 0.2);
  div {
    margin: 5px;
    font-weight: bold;
  }
  align-items: center;
}

.subscription_row_renewal_data {
  @extend %subscription_row_renewal;
  border-bottom: 1px solid rgba(0, 0, 0, 0.125);

  [data-title]:after {
    left: 20px !important;
  }

  [subscriptions-data-title]:after {
    right: 0px !important;
  }
}

.larger-checkbox {
  width: 25px !important;
  height: 25px;
}

#subscription_row_renewal_rows_container {
  .paired,
  .not_paired {
    background-color: #f2f2f2;
    border-top: 1px solid #dfe2e6;
    border-bottom: 1px solid #dfe2e6;
  }

  .indented {
    background-color: #e2e2e2;
    border: 1px solid #cbced2;
    border-radius: 0px 0px 5px 5px;
    width: calc(100% - 60px);
    margin-left: 60px;
    grid-template-columns:
      0px calc((100% - 240px) / 3) 70px calc((100% - 240px) / 3)
      90px calc((100% - 240px) / 3) 70px;
  }

  .not_paired,
  .indented {
    margin-bottom: 5px;
  }
}

#renew_subscriptions_popup_contents {
  height: min(550px, calc(100vh - 55px));

  #renew_subscriptions_popup_contents_grid {
    display: flex;
    flex-flow: column;
    align-items: flex-start;
    height: -webkit-fill-available;
  }
}
