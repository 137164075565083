%account_row {
  display: grid;
  grid-template-columns:
    200px
    calc((100% - 315px) / 6) calc((100% - 315px) / 6) calc((100% - 315px) / 6)
    calc((100% - 315px) / 6) calc((100% - 315px) / 6)
    calc((100% - 315px) / 6) 115px;

  div {
    margin: 7px;
    hyphens: auto;
    display: flex;
    align-items: center;
  }
}

#account_row_hr {
  @extend %account_row;
  border-bottom: 2px solid rgba(0, 0, 0, 0.2);
  border-top: 1px solid rgba(0, 0, 0, 0.2);
  div {
    margin: 7px;
    font-weight: bold;
  }
  align-items: center;
}

.account_row_data {
  @extend %account_row;
  min-height: 40px;
  border-top: 1px solid rgba(0, 0, 0, 0.125);
  align-items: center;
}
