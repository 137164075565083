#sync-status-wrapper p {
  font-size: 18px;
}

.update-mid {
  color: orange;
}
.update-low {
  color: green;
}
.update-high {
  color: red;
}

#sync-history-table {
  table-layout: fixed;
  width: 100%;
}

#sync-hist-wrapper {
  max-height: 450px;
  overflow-y: scroll;
}

#sync-hist-wrapper::-webkit-scrollbar {
  width: 16px;
}

#sync-hist-wrapper::-webkit-scrollbar-track {
  background: #f1f1f1; 
}
 
#sync-hist-wrapper::-webkit-scrollbar-thumb {
  background: #888;
  height: 43px;
}

#sync-hist-wrapper::-webkit-scrollbar-thumb:hover {
  background: #555; 
}
