.main-container {
  margin-top: 65px;
  margin-left: 180px;
  -ms-overflow-x: hidden;
  overflow-x: hidden;
  overflow-y: scroll;
  overflow: hidden;
}

@media screen and (max-width: 992px) {
  .main-container {
    margin-left: 0px !important;
  }
}
@media print {
  .main-container {
    margin-top: 0px !important;
    margin-left: 0px !important;
  }
}

.icon_button_container {
  display: flex;
  border: 1px solid #ced4da;
  border-radius: 5px;
  overflow: hidden;
}
.icon_button {
  button {
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .input-lg {
    height: 35px;
    padding: 10px 15px;
    border: 0;
  }
}
.visible-scrollbar::-webkit-scrollbar {
  background-color: #dedede; /* or add it to the track */
}

.visible-scrollbar::-webkit-scrollbar-thumb {
  background: #666;
}

.break-li {
  display: block;
}

.sync-text-div .alert {
  padding: 0.5rem;
}

.top-layer {
  z-index: 1050;
}

.main-scroll-wrapper {
  overflow: auto;
}

.main-scroll-wrapper::-webkit-scrollbar {
  width: 16px;
}

.main-scroll-wrapper::-webkit-scrollbar-track {
  background: #f1f1f1;
}

.main-scroll-wrapper::-webkit-scrollbar-thumb {
  background: #888;
  height: 43px;
}

.main-scroll-wrapper::-webkit-scrollbar-thumb:hover {
  background: #555;
}
