$topnav-background-color: #fff;

%centered_flex_column {
  display: flex;
  flex-flow: column;
  align-items: center;
  justify-content: center;
}

.login_flex_column {
  @extend %centered_flex_column;
  width: 100%;

  button {
    max-width: 100%;
    margin: 0px 0px 10px 0px;
    width: 250px;
  }
}

#login_whitebox {
  position: relative;
  width: 100%;
  max-height: calc(100vh - 115px);
  overflow-y: auto;
  overflow-x: hidden;
  text-align: center;
  div {
    margin: auto;
  }

  .row {
    margin: auto;
    align-items: center;
    justify-content: center;
    .alert {
      margin: 0px 0px 10px 0px;
    }
  }
}

#login_back_btn {
  position: absolute;
  top: 10px;
  left: 10px;
  z-index: 1;
}

#login_hr_contents {
  text-align: center;
}

.form_icons {
  max-width: 100%;
  width: 250px;
}

#form_container {
  @extend %centered_flex_column;

  div,
  p,
  a {
    margin-bottom: 10px;
  }
}

.login-page {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  overflow: auto;
  background: url(../assets/images/login_bg.jpg);
  background-color: #e4e5e6;
  background-size: cover;
  .login_bg_color {
    background: rgba(228, 229, 230, 0.5);
  }
  .loginbox {
    .login_box {
      padding: 50px;
      background: #fff;
      border-radius: 5px;
      border-right: 2px solid #2ca9d6;
      border-top-right-radius: 0px;
      border-bottom-right-radius: 0px;
      h4 {
        color: #000;
      }
    }
  }
  .col-lg-4 {
    padding: 0;
  }
  .input-lg {
    height: 35px;
    padding: 10px 15px;
    font-family: "SanFranciscoText-Regular";
    border: 0;
  }
  .input-underline {
    font-size: 14px;
  }
  .input-underline:focus {
    box-shadow: none;
  }
  .rounded-btn {
    -webkit-border-radius: 50px;
    border-radius: 50px;
    color: rgba(255, 255, 255, 0.8);
    background: $topnav-background-color;
    border: 2px solid rgba(255, 255, 255, 0.8);
    line-height: 40px;
    padding: 0 25px;
  }
  .rounded-btn:hover,
  .rounded-btn:focus,
  .rounded-btn:active,
  .rounded-btn:visited {
    color: rgba(255, 255, 255, 1);
    border: 2px solid rgba(255, 255, 255, 1);
    outline: none;
  }
  .row {
    margin-bottom: 5px;
  }
  h1 {
    font-weight: 400;
    font-size: 30px;
    color: #1c2225;
    font-family: "SanFranciscoText-Semibold";
    small {
      color: rgba(255, 255, 255, 0.7);
    }
  }
  p {
    font-family: "SanFranciscoText-Medium";
    color: #767a7d;
  }
  button:not(.btn-danger) {
    background: #2ca9d6;
    border: 1px solid #2ca9d6;
    span {
      font-family: "SanFranciscoText-Semibold";
    }
  }
  a {
    font-family: "SanFranciscoText-Medium";
  }
  a.btn {
    margin-left: 0;
    margin-right: 30px;
    margin-top: 5px;
    margin-bottom: 5px;
  }
  a.btn:last-child {
    margin-right: 0px;
  }

  a:not(.btn) {
    color: #2ca9d6;
  }

  .form-group {
    margin-bottom: 20px;
    position: relative;
    .has_error {
      position: absolute;
      p {
        margin-bottom: 0;
        font-family: "SanFranciscoText-Regular";
      }
    }
    label {
      padding: 0;
      color: #fff;
      margin: 0;
      a {
        color: #f9930d;
      }
    }
    input::-webkit-input-placeholder {
      color: #666 !important;
      font-size: 14px;
    }
    input:-moz-placeholder {
      /* Firefox 18- */
      color: #666 !important;
      font-size: 14px;
    }
    input::-moz-placeholder {
      /* Firefox 19+ */
      color: #666 !important;
      font-size: 14px;
    }
    input:-ms-input-placeholder {
      color: #666 !important;
      font-size: 14px;
    }
  }
  .mt-15 {
    margin-top: 10px;
  }
  .user-avatar {
    -webkit-border-radius: 50%;
    border-radius: 50%;
    border: 2px solid #fff;
  }
  mt-15 {
    margin-top: 15px;
  }
  .form_icons {
    display: flex;
    border: 1px solid #ced4da;
    border-radius: 5px;
    overflow: hidden;
    label {
      width: 50px;
      background: #e4e5e6;
      display: flex;
      justify-content: center;
      align-items: center;
      i {
        color: gray;
      }
    }
  }
}

#login_with_clever {
  background-color: #2c84d6;
  display: grid;
  grid-template-columns: 50px auto;
  grid-template-areas: "a b";
  max-width: 100%;
  width: 250px;
  border: none;
  border-bottom: 2px solid #10659d;
  border-radius: 5px 5px 0px 0px;
  color: white;
  font-weight: bold;
  cursor: pointer;
  .a,
  .b,
  h2 {
    margin: 0;
    text-align: center;
    font-weight: bold;
  }

  .a,
  .b {
    @extend %centered_flex_column;
  }

  .a {
    border-right: 1px solid #10659d;
  }

  .b {
    padding: 5px;
    margin: auto;
  }
}

#login_with_google {
  display: inline-block;
  background: white;
  color: #444;
  max-width: 100%;
  width: 250px;
  border-radius: 5px;
  border: thin solid #888;
  box-shadow: 1px 1px 1px grey;
  white-space: nowrap;
  span.label {
    font-family: serif;
    font-weight: normal;
  }
  span.icon {
    background: url('https://developers.google.com/identity/sign-in/g-normal.png') transparent 5px 50% no-repeat;
    display: inline-block;
    vertical-align: middle;
    width: 42px;
    height: 42px;
  }
  span.buttonText {
    display: inline-block;
    vertical-align: middle;
    padding-left: 42px;
    padding-right: 42px;
    font-size: 14px;
    font-weight: bold;
    font-family: 'Roboto', sans-serif;
  }
}
#login_with_google:hover {
  cursor: pointer;
}

@media screen and (min-width: 992px) {
  .login_gate {
    flex: 0 0 33.33333%;
    max-width: 33.33333%;
  }
}

@media screen and (max-width: 576px) {
  .login_gate {
    flex: 100%;
    max-width: 100%;
  }
}
