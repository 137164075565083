.carousel_container {
  height: 330px;
  width: 100%;
  grid-area: b;
  margin: 5px;
}

.default_carousel {
  display: flex;
  position: relative;
  padding: 30px;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.2);

  p {
    position: absolute;
  }
}

.carousel_btns_container {
  display: flex;
  flex-flow: row;
  justify-content: center;
  width: 100%;
}

.carousel_btn {
  width: 20px;
  height: 20px;
  background-color: #40aae3;
  border-radius: 50%;
  border: 2px solid #40aae3;
  margin: 5px;
  cursor: pointer;
}

.carousel_btn.unselected {
  background-color: white;
  border: 2px solid #7e7e7e;
}

.carousel_charts_grid {
  display: grid;
  position: relative;
  grid-template-columns: 300px 300px;
  height: 300px;
  width: 100%;
  overflow: hidden;
}

.carousel_chart {
  position: absolute;
  transition: left 0.5s;
  width: 100%;
  height: 100%;

  .row,
  .card,
  .mx-4 {
    margin: 0;
  }

  .col-12 {
    padding: 0;
  }
}
