.help-number {
  text-align: center;
  grid-area: a;
}

.help-topbar {
  text-align: center;
  grid-area: b;
}

.help-sidebar {
  color: blue;
  grid-area: c;
  padding-right: 20px;
  border-right: 1px solid rgba(0, 0, 0, 0.2);
  ul {
    padding-left: 0px;
    width: fit-content;
    margin: auto;
  }
}

.help-main {
  grid-area: d;
  overflow-y: auto;
  padding: 20px;
}

.help-grid-container {
  display: grid;
  height: 100%;
  margin: 0px 0px 0px 20px;
  grid-template-rows: max-content;
  grid-template-columns: 260px calc((100% - 260px) / 2) calc((100% - 260px) / 2);
  grid-template-areas:
    "a b b"
    "c d d"
    "c d d";
  row-gap: 3px;
  //column-gap: 20px;
  padding-top: 20px;
}

.bulk_learner_content {
  padding-left: 5%;
  padding-right: 20px;
}

#bulk_learner_popup_wrapper {
  transform: translate(-50%, 0%);
  top: 10vh;
  right: unset;
  width: 90%;
  max-width: 600px;
}

#bulk_learner_help_popup_wrapper {
  height: fit-content;
  transform: translate(-40%, 0%);
  top: 5%;
  right: 50%;
  left: 50%;
  width: 60vw;
  max-width: 900px;
}

#help-grid-and-btns-container {
  display: grid;
  width: 100%;
  align-items: flex-start;
  height: calc(70vh - 55px);
  grid-template-rows: calc(100% - 90px) 90px;
}

#help-grid-buttons {
  display: flex;
  justify-content: center;
  width: 66.6%;
  margin-left: auto;
  margin-right: auto;
  padding: 20px 0px 20px 0px;
}

.bulk_learner_information {
  height: calc(80vh - 55px);
  overflow-y: auto;
  height: 100%;
  max-height: 700px;
}

.bulk_learner_information::-webkit-scrollbar {
  background-color: #dedede; /* or add it to the track */
}

.bulk_learner_information::-webkit-scrollbar-thumb {
  background: #666;
}

@media screen and (max-height: 700px) {
  #bulk_learner_popup_wrapper {
    top: 10px;
  }

  .bulk_learner_information {
    height: calc(80vh - 55px);
    overflow-y: auto;
  }

  #bulk_learner_help_popup_wrapper {
    top: 10px;
  }

  #help-grid-and-btns-container {
    display: grid;
    align-items: flex-start;
    height: calc(100vh - 55px);
    grid-template-rows: calc(100% - 90px) 90px;
  }
}

@media screen and (max-width: 992px) {
  #bulk_learner_help_popup_wrapper {
    transform: translate(-50%, 0%);
    top: 5%;
    right: 50%;
    left: 50%;
    width: 100vw;
    max-width: 100vw;
  }
}

.compact-lists {
  p {
    padding: 0;
    margin: 0;
  }
}
