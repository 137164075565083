.create_assignment_wrapper {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 100%;
  max-width: 1200px;
  z-index: 1050;
}

#create_assignment_grid_container {
  display: grid;
  height: 100%;
  width: 100%;
  justify-content: center;
  grid-template-rows: 70px calc(100% - 120px) 30px;
  grid-gap: 10px;
  grid-template-columns: 100%;
}

.create_assignment_content_container {
  height: calc(100vh - 95px);
}

.create_assignment_popup_grid {
  display: flex;
  flex-flow: column;
  max-height: calc(80vh - 55px);
}

@media screen and (max-width: 992px) {
  .create_assignment_content_container {
    overflow: auto;
  }
}
