/* You can add global styles to this file, and also import other style files */

@import "bootstrap/bootstrap";
@import "spinner";
@import "utils";
@import "rtl";
@import "responsive";
// import "~ngx-toastr/toastr.css";

@media print {
  .breadcrumb {
    display: none !important;
  }
}
/*
import '~@swimlane/ngx-datatable/release/index.css';
import '~@swimlane/ngx-datatable/release/themes/material.css';
import '~@swimlane/ngx-datatable/release/assets/icons.css';
*/
/*fonts */

@font-face {
  font-family: "SanFranciscoText-Light";
  src: url("../assets/fonts/SanFranciscoText-Light.eot?#iefix")
      format("embedded-opentype"),
    url("../assets/fonts/SanFranciscoText-Light.otf") format("opentype"),
    url("../assets/fonts/SanFranciscoText-Light.woff") format("woff"),
    url("../assets/fonts/SanFranciscoText-Light.ttf") format("truetype"),
    url("../assets/fonts/SanFranciscoText-Light.svg#SanFranciscoText-Light")
      format("svg");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "SanFranciscoText-Regular";
  src: url("../assets/fonts/SanFranciscoText-Regular.eot?#iefix")
      format("embedded-opentype"),
    url("../assets/fonts/SanFranciscoText-Regular.otf") format("opentype"),
    url("../assets/fonts/SanFranciscoText-Regular.woff") format("woff"),
    url("../assets/fonts/SanFranciscoText-Regular.ttf") format("truetype"),
    url("../assets/fonts/SanFranciscoText-Regular.svg#SanFranciscoText-Regular")
      format("svg");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "SanFranciscoText-Medium";
  src: url("../assets/fonts/SanFranciscoText-Medium.eot?#iefix")
      format("embedded-opentype"),
    url("../assets/fonts/SanFranciscoText-Medium.otf") format("opentype"),
    url("../assets/fonts/SanFranciscoText-Medium.woff") format("woff"),
    url("../assets/fonts/SanFranciscoText-Medium.ttf") format("truetype"),
    url("../assets/fonts/SanFranciscoText-Medium.svg#SanFranciscoText-Medium")
      format("svg");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "SanFranciscoText-Semibold";
  src: url("../assets/fonts/SanFranciscoText-Semibold.eot?#iefix")
      format("embedded-opentype"),
    url("../assets/fonts/SanFranciscoText-Semibold.otf") format("opentype"),
    url("../assets/fonts/SanFranciscoText-Semibold.woff") format("woff"),
    url("../assets/fonts/SanFranciscoText-Semibold.ttf") format("truetype"),
    url("../assets/fonts/SanFranciscoText-Semibold.svg#SanFranciscoText-Semibold")
      format("svg");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "SanFranciscoText-Bold";
  src: url("../assets/fonts/SanFranciscoText-Bold.eot?#iefix")
      format("embedded-opentype"),
    url("../assets/fonts/SanFranciscoText-Bold.otf") format("opentype"),
    url("../assets/fonts/SanFranciscoText-Bold.woff") format("woff"),
    url("../assets/fonts/SanFranciscoText-Bold.ttf") format("truetype"),
    url("../assets/fonts/SanFranciscoText-Bold.svg#SanFranciscoText-Bold")
      format("svg");
  font-weight: normal;
  font-style: normal;
}

$button-background-color: #2f73ba;

.pl-15 {
  padding-left: 15px;
}

.pl-30 {
  padding-left: 30px;
}

.fs-14 {
  font-size: 14px;
}

.bold_font {
  font-family: "SanFranciscoText-Medium" !important;
}

.mb-20 {
  margin-bottom: 20px !important;
}

.mb-05 {
  margin-bottom: 5px;
}

.mb-30 {
  margin-bottom: 30px;
}

.mt-20 {
  margin-top: 20px;
}

.mt-15 {
  margin-top: 15px;
}

.mr-10 {
  margin-right: 10px;
}
.ml-10 {
  margin-left: 10px;
}

.mt-30 {
  margin-top: 30px;
}
.mr-20 {
  margin-right: 20px;
}
.p-20 {
  padding: 20px;
}
.blue-text {
  color: #2ca9d6;
}
.white {
  color: #fff;
}
.disable {
  cursor: no-drop;
}
.btn-rounded {
  width: 20px;
  border-radius: 50%;
  height: 20px;
  display: inline-flex;
  justify-content: center;
  align-items: center;
}
.btn-rounded i {
  line-height: 10px;
}

/* For Firefox */
input[type="number"] {
  -moz-appearance: textfield;
}
/* Webkit browsers like Safari and Chrome */
input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.common_dashboard_bg {
  background: #fff;
  padding: 50px;
}

.robot_dashboard_bg {
  background: #fff;
  padding: 0px;
}

.robot_page_bg {
  background: #fff;
  padding-left: 25px;
  padding-right: 25px;
  padding-top: 10px;
  padding-bottom: 25px;
}

.error_border {
  border: 1px solid red;
}

.row.batch-list {
  .col-md-3 {
    margin-bottom: 15px;
    padding-right: 0px;
  }

  .card {
    height: 100%;

    .card-body {
      padding: 25px 5px;
    }
  }
}

.table_icons {
  display: flex;
  border: 1px solid #ced4da;
  border-radius: 5px;
  overflow: hidden;

  input {
    border: 0;
  }

  label {
    width: 50px;
    background: #e4e5e6;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 0;

    i {
      color: gray;
    }
  }
}

.row.log_flex .col-md-6 div {
  display: flex;

  p {
    margin-bottom: 5px;

    &:first-child {
      width: 40%;
    }
  }

  span {
    padding: 0px 10px;
  }
}

.row.flex-column {
  flex-flow: column;
}

.accordian_div .card-header button {
  width: 100%;
  text-decoration: none;
  padding: 0px 0px;

  .icon_up {
    display: inline;
  }

  .icon_down {
    display: none;
  }

  &.collapsed {
    .icon_down {
      display: inline-block;
    }

    .icon_up {
      display: none;
    }
  }
}

.accordian_flex {
  display: flex;

  p {
    margin-bottom: 0;
    color: #000;
    font-size: 14px;
    display: flex;
    justify-content: space-between;

    &:nth-of-type(2) {
      padding: 0 10px;
    }
  }

  span {
    padding: 0 10px;
    color: #000;
  }
}

/*table*/
.school_listing_table datatable-header-cell.datatable-header-cell:nth-child(1),
.school_listing_table datatable-body-cell.datatable-body-cell:nth-child(1) {
  width: 12% !important;
}

.school_listing_table datatable-header-cell.datatable-header-cell:nth-child(2),
.school_listing_table datatable-body-cell.datatable-body-cell:nth-child(2) {
  width: 18% !important;
}

.school_listing_table datatable-header-cell.datatable-header-cell:nth-child(3),
.school_listing_table datatable-body-cell.datatable-body-cell:nth-child(3) {
  width: 14% !important;
}

.school_listing_table datatable-header-cell.datatable-header-cell:nth-child(4),
.school_listing_table datatable-body-cell.datatable-body-cell:nth-child(4) {
  width: 14% !important;
}

.school_listing_table datatable-header-cell.datatable-header-cell:nth-child(5),
.school_listing_table datatable-body-cell.datatable-body-cell:nth-child(5) {
  width: 14% !important;
}

.school_listing_table datatable-header-cell.datatable-header-cell:nth-child(6),
.school_listing_table datatable-body-cell.datatable-body-cell:nth-child(6) {
  width: 12% !important;
}

.school_listing_table datatable-header-cell.datatable-header-cell:nth-child(7),
.school_listing_table datatable-body-cell.datatable-body-cell:nth-child(7) {
  width: 16% !important;
}

.accordian_inner_table datatable-header-cell.datatable-header-cell:nth-child(1),
.accordian_inner_table datatable-body-cell.datatable-body-cell:nth-child(1) {
  width: 17% !important;
}

.accordian_inner_table datatable-header-cell.datatable-header-cell:nth-child(2),
.accordian_inner_table datatable-body-cell.datatable-body-cell:nth-child(2) {
  width: 23% !important;
}

.accordian_inner_table.super_admin_device
  datatable-header-cell.datatable-header-cell:nth-child(2),
.accordian_inner_table.super_admin_device
  datatable-body-cell.datatable-body-cell:nth-child(2) {
  width: 18% !important;
}

.accordian_inner_table datatable-header-cell.datatable-header-cell:nth-child(3),
.accordian_inner_table datatable-body-cell.datatable-body-cell:nth-child(3) {
  width: 15% !important;
}

.accordian_inner_table datatable-header-cell.datatable-header-cell:nth-child(4),
.accordian_inner_table datatable-body-cell.datatable-body-cell:nth-child(4) {
  width: 15% !important;
}

.accordian_inner_table datatable-header-cell.datatable-header-cell:nth-child(5),
.accordian_inner_table datatable-body-cell.datatable-body-cell:nth-child(5) {
  width: 15% !important;
}

.accordian_inner_table datatable-header-cell.datatable-header-cell:nth-child(6),
.accordian_inner_table datatable-body-cell.datatable-body-cell:nth-child(6) {
  width: 15% !important;
}

.accordian_inner_table datatable-header-cell.datatable-header-cell:nth-child(7),
.accordian_inner_table datatable-body-cell.datatable-body-cell:nth-child(7) {
  width: 9% !important;
}

.accordian_inner_unused_table
  datatable-header-cell.datatable-header-cell:nth-child(1),
.accordian_inner_unused_table
  datatable-body-cell.datatable-body-cell:nth-child(1) {
  width: 50% !important;
}

.accordian_inner_unused_table
  datatable-header-cell.datatable-header-cell:nth-child(2),
.accordian_inner_unused_table
  datatable-body-cell.datatable-body-cell:nth-child(2) {
  width: 25% !important;
}

.accordian_inner_unused_table
  datatable-header-cell.datatable-header-cell:nth-child(3),
.accordian_inner_unused_table
  datatable-body-cell.datatable-body-cell:nth-child(3) {
  width: 25% !important;
}

.popup_table datatable-header-cell.datatable-header-cell,
.popup_table datatable-body-cell.datatable-body-cell {
  padding: 14px 5px !important;
  word-break: break-word;
}

.manage_learner_table datatable-header-cell.datatable-header-cell:nth-child(1),
.manage_learner_table datatable-body-cell.datatable-body-cell:nth-child(1) {
  width: 17% !important;
}

.manage_learner_table datatable-header-cell.datatable-header-cell:nth-child(2),
.manage_learner_table datatable-body-cell.datatable-body-cell:nth-child(2) {
  width: 17% !important;
}

.manage_learner_table datatable-header-cell.datatable-header-cell:nth-child(3),
.manage_learner_table datatable-body-cell.datatable-body-cell:nth-child(3) {
  width: 12% !important;
}

.manage_learner_table datatable-header-cell.datatable-header-cell:nth-child(4),
.manage_learner_table datatable-body-cell.datatable-body-cell:nth-child(4) {
  width: 29% !important;
}

.manage_learner_table datatable-header-cell.datatable-header-cell:nth-child(5),
.manage_learner_table datatable-body-cell.datatable-body-cell:nth-child(5) {
  width: 15% !important;
}

.manage_learner_table datatable-header-cell.datatable-header-cell:nth-child(6),
.manage_learner_table datatable-body-cell.datatable-body-cell:nth-child(6) {
  width: 10% !important;
}

.student_login_table datatable-header-cell.datatable-header-cell:nth-child(1),
.student_login_table datatable-body-cell.datatable-body-cell:nth-child(1) {
  width: 35% !important;
}

.student_login_table datatable-header-cell.datatable-header-cell:nth-child(2),
.student_login_table datatable-body-cell.datatable-body-cell:nth-child(2) {
  width: 35% !important;
}

.student_login_table datatable-header-cell.datatable-header-cell:nth-child(3),
.student_login_table datatable-body-cell.datatable-body-cell:nth-child(3) {
  width: 15% !important;
}

.student_login_table datatable-header-cell.datatable-header-cell:nth-child(4),
.student_login_table datatable-body-cell.datatable-body-cell:nth-child(4) {
  width: 15% !important;
}

.sync_table datatable-header-cell.datatable-header-cell:nth-child(1),
.sync_table datatable-body-cell.datatable-body-cell:nth-child(1) {
  width: 30% !important;
}

.sync_table datatable-header-cell.datatable-header-cell:nth-child(2),
.sync_table datatable-body-cell.datatable-body-cell:nth-child(2) {
  width: 30% !important;
}

.sync_table datatable-header-cell.datatable-header-cell:nth-child(3),
.sync_table datatable-body-cell.datatable-body-cell:nth-child(3) {
  width: 40% !important;
}
// import_table
.import_table datatable-header-cell.datatable-header-cell:nth-child(1),
.import_table datatable-body-cell.datatable-body-cell:nth-child(1) {
  width: 5% !important;
}

.import_table datatable-header-cell.datatable-header-cell:nth-child(2),
.import_table datatable-body-cell.datatable-body-cell:nth-child(2) {
  width: 13% !important;
}

.import_table datatable-header-cell.datatable-header-cell:nth-child(3),
.import_table datatable-body-cell.datatable-body-cell:nth-child(3) {
  width: 13% !important;
}

.import_table datatable-header-cell.datatable-header-cell:nth-child(4),
.import_table datatable-body-cell.datatable-body-cell:nth-child(4) {
  width: 10% !important;
}

.import_table datatable-header-cell.datatable-header-cell:nth-child(5),
.import_table datatable-body-cell.datatable-body-cell:nth-child(5) {
  width: 20% !important;
}

.import_table datatable-header-cell.datatable-header-cell:nth-child(6),
.import_table datatable-body-cell.datatable-body-cell:nth-child(6) {
  width: 10% !important;
}

.import_table datatable-header-cell.datatable-header-cell:nth-child(7),
.import_table datatable-body-cell.datatable-body-cell:nth-child(7) {
  width: 20% !important;
}

.import_table datatable-header-cell.datatable-header-cell:nth-child(8),
.import_table datatable-body-cell.datatable-body-cell:nth-child(8) {
  width: 9% !important;
}

// import_table
.importing_table datatable-header-cell.datatable-header-cell:nth-child(1),
.importing_table datatable-body-cell.datatable-body-cell:nth-child(1) {
  width: 5% !important;
}

.importing_table datatable-header-cell.datatable-header-cell:nth-child(2),
.importing_table datatable-body-cell.datatable-body-cell:nth-child(2) {
  width: 13% !important;
}

.importing_table datatable-header-cell.datatable-header-cell:nth-child(3),
.importing_table datatable-body-cell.datatable-body-cell:nth-child(3) {
  width: 13% !important;
}

.importing_table datatable-header-cell.datatable-header-cell:nth-child(4),
.importing_table datatable-body-cell.datatable-body-cell:nth-child(4) {
  width: 10% !important;
}

.importing_table datatable-header-cell.datatable-header-cell:nth-child(5),
.importing_table datatable-body-cell.datatable-body-cell:nth-child(5) {
  width: 20% !important;
}

.importing_table datatable-header-cell.datatable-header-cell:nth-child(6),
.importing_table datatable-body-cell.datatable-body-cell:nth-child(6) {
  width: 10% !important;
}

.importing_table datatable-header-cell.datatable-header-cell:nth-child(7),
.importing_table datatable-body-cell.datatable-body-cell:nth-child(7) {
  width: 20% !important;
}

body {
  font-size: 14px;
  font-family: "SanFranciscoText-Light";
}

.button_bg_primary {
  background: $button-background-color;
}

/*stepper*/
.stepper_wrapper {
  background: #fff;
}

.row.stepper_row {
  width: 60%;
  margin: 0 auto;

  .col-md-3 {
    padding: 0;

    p {
      margin-top: 15px;
      margin-bottom: 0;
      text-align: center;

      &.active {
        color: #2ca9d6;
      }
    }
  }

  .col-md-4 {
    padding: 0;

    p {
      margin-top: 15px;
      margin-bottom: 0;
      text-align: center;

      &.active {
        color: #2ca9d6;
      }
    }
  }
}

.step_row {
  display: flex;
  align-items: center;

  .step_round {
    width: 30px;
    height: 30px;
    background: #868e96;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50%;

    &.active {
      background: #2ca9d6;
    }

    span {
      color: #fff;

      &.active {
        color: #fff;
      }
    }
  }

  .step_line {
    background: #868e96;
    height: 2px;
    width: calc(50% - 15px);

    &.active {
      background: #2ca9d6;
    }

    &.color {
      background: transparent;
    }
  }
}

.custom_breadcrumb {
  background-color: transparent;
  padding: 1rem 3rem;
  margin-bottom: 0;
  border-bottom: 1px solid #c8ced3;
  // box-shadow: 1px 1px 10px #e3e6e8;
  border-radius: 0px;

  li {
    &:before {
      content: "" !important;
    }

    &.active {
      color: #212529;
    }

    a {
      font-size: 14px;
      font-family: "SanFranciscoText-Medium";
      color: #2ca9d6;
      text-decoration: none;
    }

    span {
      padding: 0px 8px;
    }
  }

  .breadcrumb-item + .breadcrumb-item::before {
    padding-left: 0;
    padding-right: 0;
  }
}

.form-group.add-devie_button {
  margin-top: 36px;

  button {
    margin-top: 5px;
  }
}

.common_padding_space {
  padding: 3rem 3rem;
  min-height: 630px;
  background-image: url("../assets/images/cloud_logo_pattern.svg");
  background-size: 700px;
  background-repeat: repeat;
  background-color: #e4e5e6;

  .form-group {
    margin-bottom: 25px;

    label {
      font-size: 14px;
    }

    input {
      font-size: 14px;
      height: calc(2.25rem + 2px);
    }

    select {
      font-size: 14px;
    }

    textarea {
      font-size: 14px;
    }
  }

  .form-check {
    label {
      font-size: 14px;
    }
  }
}

.custom_upload {
  position: relative;

  input {
    z-index: 1;
    position: relative;
    cursor: pointer;
  }

  i {
    position: absolute;
    left: 0px;
    top: 6px;
    color: #007bff;
  }
}

.user_table table {
  width: 100%;
}

// footer start
footer {
  position: relative;
  width: 100%;
  background: #000;
  z-index: 10000000;

  .footer_div {
    display: flex;
    justify-content: center;
    padding: 15px 0;

    ul {
      margin-bottom: 0;
      display: flex;

      li {
        a {
          color: #fff !important;
        }

        span {
          color: #fff;
          padding: 0px 20px;
        }
      }
    }
  }
}

::-webkit-scrollbar {
  width: 8px;
}

::-webkit-scrollbar-track {
  border-radius: 3px;
}

::-webkit-scrollbar-thumb {
  border-radius: 3px;
}

/*dashboard */
.dashboard_card {
  padding: 30px 0px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  display: flex;

  img {
    margin-bottom: 20px;
  }

  a {
    color: #fff;
    font-size: 18px;
    font-family: "SanFranciscoText-Medium";
    text-decoration: underline;
  }
}

// footer end
/*datatable actions*/
.datatable_actions a i {
  font-size: 16px;
  color: #2ca9d6;
}

.datatable_actions a,
.datatable_actions button {
  display: inline-block;
  margin-right: 2px;

  img {
    height: 22px;
    margin-top: -7px;
  }
}

table button.showPW {
  font-family: inherit;
  font-size: 100%;
  line-height: 1.15;
  margin: 0;
  display: inline-block;
}

.ngx-datatable.material .datatable-header {
  border-top: 1px solid #c8ced3;
  border-bottom: 1px solid #c8ced3;
}

.ngx-datatable .datatable-body .datatable-row-odd {
  background-color: rgba(0, 0, 0, 0.05);
}

.ngx-datatable .datatable-body .datatable-row-wrapper {
  border-bottom: 1px solid #c8ced3;
}

.ngx-datatable .datatable-body .datatable-row-wrapper:last-child {
  border-bottom: 0px;
}

.school_view h4 {
  font-family: "SanFranciscoText-Medium";
  color: #2f353a;
}

/*school listing*/
.common_border {
  border: 1px solid #c8ced3;
}

.common_heading {
  padding: 15px 15px;
  background: #f0f3f5;
  border-bottom: 1px solid #c8ced3;

  p {
    margin-bottom: 0;
    font-family: "SanFranciscoText-Medium";
    font-size: 16px;
  }
}

.common_table_padding {
  background: #fff;
  padding: 15px 30px 30px 30px;
}

.school_listing .ngx-datatable {
  box-shadow: none;
}

.school_listing.accordian_inner_table .ngx-datatable {
  box-shadow: none;
  border: 1px solid #c8ced3;
  border-top: 0;
}

span.datatable-header-cell-label {
  color: #000;
  font-family: "SanFranciscoText-Semibold";
}

.view_school_header {
  padding: 5px 15px;
  color: #2ca9d6;
  font-family: "SanFranciscoText-Medium";
}

.school_view p {
  font-family: "SanFranciscoText-Regular";
}

.divice_pos {
  padding-top: 20px;
}

.device_abs {
  position: absolute;
  top: -10px;
  left: 20px;
  background: #fff;
}

//parent
.parent_conent p {
  text-align: justify;
}

.parent_conent_center p {
  margin: 0 auto;
}

.card.file_upload_custum {
  border: 2px dashed rgba(0, 0, 0, 0.125);
}
.card.file_upload_custum .custom-file input {
  height: 100%;
  cursor: pointer;
}
.card.file_upload_custum .custom-file label {
  display: none;
  cursor: pointer;
}
.card.file_upload_custum .custom-file {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  cursor: pointer;
}

.file_upload_icon i {
  font-size: 40px;
  color: rgba(0, 0, 0, 0.125);
}

// nav.sidebar {
//     background: url(assets/images/sidebar-menu.png);
//     background-position: left bottom;
//     background-size: 85% auto;
//     background-repeat: no-repeat;
// }
.bottom_logo img {
  width: 78%;
  position: absolute;
  bottom: 0;
  opacity: 0.4;
  left: 20px;
}

.batch_listings p {
  font-size: 18px;
}

.batch_listings div a {
  font-size: 18px;
  color: #fff !important;
  cursor: pointer;

  // &:first-child {
  //     margin-right: 20px;
  // }
}

.row.batch-row .col-md-3 {
  margin-bottom: 15px;
  padding: 0px 15px 0px 0px;
}
.view_learner_popups .row .row p {
  margin-bottom: 10px;
}
.view_learner_popups {
  padding: 20px;
}
.common_heading.view_learner_heading.bg-danger {
  padding: 10px 15px;
}
.myCustomModalClass .modal-dialog {
  max-width: 950px;
}
.print_listings {
  margin: 0;
}
.print_listings p {
  margin: 0;
}
.group_list_wrapper {
  margin-bottom: 15px;
}
.row.print_listings {
  border: 1px solid #dee2e6;
  border-bottom: 0;
}

.chart-legend {
  position: absolute;
  top: -40px;
  left: 0;
}
.chart-legend .legend-title {
  display: none;
}
.chart-legend .legend-labels {
  display: flex;
  background: none !important;
  justify-content: center;
}
.chart-legend .legend-label {
  float: left;
}
// PDF CSs
.pdf-upload {
  width: 100%;
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background-color: rgba(228, 229, 230, 0.5);
  z-index: 1040;
}
.pdf-upload-wrapper {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 100%;
  max-width: 565px;
  z-index: 1050;
}
.pdf-upload-wrapper-large {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 100%;
  max-width: 950px;
  // min-height: 650px;
  z-index: 1050;
}
.pdf-upload-wrapper-medium {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 100%;
  max-width: 700px;
  min-height: 550px;
  z-index: 1050;
}
.pdf-upload-wrapper-small {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 100%;
  max-width: 350px;
  //min-height: 300px;
  z-index: 1050;
}
.pdf-upload-wrapper-large-offset {
  position: absolute;
  top: 25%;
  left: 55%;
  transform: translate(-50%, -50%);
  background: #fff;
  width: 100%;
  max-width: 950px;
  // min-height: 650px;
  z-index: 1060;
}
.pdf_popup_close {
  position: absolute;
  right: 20px;
  top: 15px;
  cursor: pointer;
  border: 0px;
  background-color: transparent;
}
.edit-audio-wrapper {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 100%;
  max-width: 565px;
  max-height: 75%;
  overflow-y: scroll;
}
.edit-audio-wrapper::-webkit-scrollbar-track {
  background: #f1f1f1;
}

.edit-audio-wrapper::-webkit-scrollbar-thumb {
  background: #888;
}

.edit-audio-wrapper::-webkit-scrollbar-thumb:hover {
  background: #555;
}
.edit-audio-wrapper audio-wrapper {
  max-width: 100%;
}
.audio-wrapper audio {
  max-width: 100%;
}
.combined-label-inputs {
  button {
    margin-right: 5px;
  }
  .form-group {
    margin-bottom: 20px;
    position: relative;
    .has_error {
      position: absolute;
      p {
        margin-bottom: 0;
        font-family: "SanFranciscoText-Regular";
      }
    }
    label {
      padding: 0;
      color: #fff;
      margin: 0;
      a {
        color: #f9930d;
      }
    }
    input::-webkit-input-placeholder {
      color: #666 !important;
      font-size: 14px;
    }
    input:-moz-placeholder {
      /* Firefox 18- */
      color: #666 !important;
      font-size: 14px;
    }
    input::-moz-placeholder {
      /* Firefox 19+ */
      color: #666 !important;
      font-size: 14px;
    }
    input:-ms-input-placeholder {
      color: #666 !important;
      font-size: 14px;
    }
  }
  .form_icons {
    display: flex;
    border: 1px solid #ced4da;
    border-radius: 5px;
    overflow: hidden;
    label {
      width: 50px;
      background: #e4e5e6;
      display: flex;
      justify-content: center;
      align-items: center;
      i {
        color: gray;
      }
    }
  }
}

.show-scrollbar::-webkit-scrollbar {
  width: 16px;
}

.show-scrollbar::-webkit-scrollbar-track {
  background: #f1f1f1;
}

.show-scrollbar::-webkit-scrollbar-thumb {
  background: #888;
  height: 43px;
}

.show-scrollbar::-webkit-scrollbar-thumb:hover {
  background: #555;
}

.sticky-first-col {
  left: 0 !important;
  background-color: white;
  position: sticky !important;
  position: -webkit-sticky !important;
  z-index: 100 !important;
}

/* Sales */

.item-label {
  font-weight: bold;
  min-width: 80px;
}

.table.table-left-align-first-column {
  td:first-child {
    text-align: left;
  }
}

.capitalize-text {
  text-transform: capitalize;
}

.table-filter-wrapper {
  border: 3px solid #ced4da;
}

input[type="radio"].medium-radio,
input[type="checkbox"].medium-checkbox {
  transform: scale(1.5);
}

.alert-success-2 {
  color: #357343;
  background-color: #d4edda8f;
  border-color: #c3e6cb;
}

/* SSO */

#linked_account_grid {
  display: grid;
  grid-template-columns: 42px auto;
  grid-template-areas: a b;
  grid-gap: 5px;
  b {
    vertical-align: middle;
  }
}

#clever_icon {
  background-color: #2c84d6;
  //display: grid;
  //grid-template-columns: 50px auto;
  //grid-template-areas: a b;
  //max-width: 100%;
  width: 42px;
  border-bottom: 2px solid #10659d;
  border-radius: 5px 5px 0px 0px;
  color: white;
  font-weight: bold;
  cursor: pointer;
  p {
    margin: 0;
    text-align: center;
    font-weight: bold;
  }
}

#google_icon {
  display: inline-block;
  background: white;
  color: #444;
  width: 42px;
  height: 42px;
  border-radius:  5px;
  border: thin solid #888;
  box-shadow: 1px 1px 1px grey;
  span.icon {
    background: url('https://developers.google.com/identity/sign-in/g-normal.png') transparent 5px 50% no-repeat;
    display: inline-block;
    vertical-align: middle;
    width: 42px;
    height: 42px;
  }
}
