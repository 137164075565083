:host {
  display: block;
}

.logged-out-box {
  padding: 50px;
  background: #fff;
  border-radius: 5px;
  text-align: center;
}
