.assignment-name-and-triangle-grid {
  display: grid;
  grid-template-columns: 20px auto;
  align-items: center;

  button {
    background-color: transparent;
  }

  // overrides Class.scss
  .triangle-up {
    padding: 0px;
    border-bottom: none;
  }

  // overrides Class.scss
  .triangle-down {
    border-right: 0px solid transparent;
  }
}

.expanded_assignment_container {
  border-top: 1px solid #d7d7d7;
  transition: max-height 0.5s ease;
}
