#org_sales_table_container,
#all_sales_table_container {
  width: 100%;
  max-height: 600px;
  overflow: auto;
}

%org_sales_table_row {
  display: grid;
  text-align: center;
  border-top: 1px solid rgba(0, 0, 0, 0.125);
  div,
  p {
    width: 100%;
    padding: 5px;
    margin: 0;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    hyphens: auto;
  }
}

.all_sales_table_row {
  @extend %org_sales_table_row;
  grid-template-columns:
    60px calc((100% - 455px) / 6) calc((100% - 455px) / 6) 100px
    calc((100% - 455px) / 6) calc((100% - 455px) / 6) 105px
    calc((100% - 455px) / 6) 60px
    calc((100% - 455px) / 6) 60px 70px;
  min-width: 930px;
}

.org_sales_table_row {
  @extend %org_sales_table_row;
  grid-template-columns:
    60px calc((100% - 130px) / 6) calc((100% - 130px) / 6)
    calc((100% - 130px) / 6) calc((100% - 130px) / 6)
    calc((100% - 130px) / 6)
    calc((100% - 130px) / 6) 70px;
  min-width: 630px;
}

#org_sales_table_hr_row,
#all_sales_table_hr_row {
  font-weight: bold;
}
