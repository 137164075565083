#circle_page_indicator_container {
  max-width: 600px;
  width: calc(100% - 50px);
  margin: auto;
}

#circles_and_lines {
  display: grid;
  width: 100%;
  text-align: center;
  justify-items: center;
  align-items: center;
  grid-template-columns:
    120px calc((100% - 480px) / 3) 120px calc((100% - 480px) / 3)
    120px calc((100% - 480px) / 3) 120px;

  grid-template-areas:
    "a line1 b line2 c line3 d"
    "e none1 f none2 g none3 h";

  hr {
    border: 0.5px solid #7e7e7e;
    width: calc(100% + 40px);
    min-width: 20px;
    margin: auto;
    translate: -20px;
  }

  .create_assignment_circle {
    width: 20px;
    height: 20px;
    border: 1px solid #7e7e7e;
    border-radius: 50%;
    background-color: white;
  }

  .current_page {
    background-color: #62b6e6;
    border: none;
  }

  p {
    text-align: center;
  }
}

@media screen and (max-width: 992px) {
  #circle_page_indicator_container {
    width: 100%;
    margin: auto;
    overflow-x: auto;

    hr {
      border: 0.5px solid #7e7e7e;
      width: calc(100% + 40px);
      min-width: 20px;
      margin: auto;
      translate: -20px;
    }
  }
}
