#class_tab_body {
  display: grid;
  grid-template-columns: 45% 55%;
  grid-template-areas:
    "a b"
    "c c"
    "d d";
}

#options_container {
  display: flex;
  flex-flow: column;
  grid-area: a;
}

#class_tab_header_container {
  display: grid;
  grid-template-columns: 45% 55%;
  align-items: center;
}

#gray_pdf_download_overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(159, 159, 159, 0.6);
  display: none;
  align-items: center;
  justify-content: center;
  z-index: 1;
  font-size: 150%;
}

@media screen and (max-width: 992px) {
  #class_tab_body {
    display: grid;
    grid-template-columns: 45% 55%;
    grid-template-areas:
      "a a"
      "b b"
      "c c"
      "d d";
  }

  #class_tab_header_container {
    display: flex;
    flex-flow: column;
    text-align: center;
  }
}
