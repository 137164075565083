.card,
.batch-row {
  margin-bottom: 2em;
}

.intro-vids {
  margin: 56px auto;
  display: block;
}

.form_icons {
  width: 100%;
  max-width: 250px;
}

.table-scroll-wrapper {
  max-height: 600px;
  overflow: auto;
}

.table-scroll-wrapper::-webkit-scrollbar {
  width: 16px;
}

.table-scroll-wrapper::-webkit-scrollbar-track {
  background: #f1f1f1;
}

.table-scroll-wrapper::-webkit-scrollbar-thumb {
  background: #888;
  height: 43px;
}

.table-scroll-wrapper::-webkit-scrollbar-thumb:hover {
  background: #555;
}

.line-break {
  margin: 0 !important;
  height: 0;
  width: 100%;
}

#filters_fields_container {
  .row-1 {
    align-items: baseline;
    grid-gap: 2%;
    display: grid;
    grid-template-columns:
      calc(90% / 4) calc(90% / 4) calc(90% / 4)
      calc(90% / 4);
  }
  .row-2 {
    align-items: baseline;
    grid-gap: 2%;
    display: flex;
    flex-flow: row;
    flex-wrap: wrap;
    align-items: center;
  }
}

.reset_filters_btn {
  width: fit-content;
  align-self: baseline;
  justify-self: flex-end;
}

.org-actions {
  position: relative;
}

.org-actions[data-title]:after {
  display: flex;
  align-items: left;
  justify-content: flex-end;
  top: 100%;
  right: 0%;
  left: unset !important;
  min-width: fit-content;
}

.left-data-title[data-title]:after {
  display: flex;
  align-items: left;
  justify-content: flex-end;
  right: 0%;
  left: 50px !important;
  min-width: fit-content;
}
