div.showPW {
    display: flex;
    border: 1px solid #ced4da;
    border-radius: 5px;
    overflow: hidden;
}

div.showPW button {
    padding: 0;
    color: #fff;
    margin: 0;
    width: 50px;
    background: #e4e5e6;
    display: flex;
    justify-content: center;
    align-items: center;
}

[data-title]:hover:after {
    opacity: 1;
    transition: all 0.1s ease 0.5s;
    visibility: visible;
}

[data-title]:after {
    content: attr(data-title);
    background-color: #2f353a;
    color: white;
    font-size: 120%;
    position: absolute;
    padding: 3px 6px;
    bottom: -1.6em;
    right: 0px;
    left: unset !important;
    width: fit-content;
    //white-space: nowrap;
    box-shadow: 1px 1px 3px #222222;
    opacity: 0;
    border-radius: 5px;
    z-index: 99999;
    visibility: hidden;
    display: flex;
    align-items: right;
    justify-content: right;
}

[data-title] {
    position: relative;
}


.disabled-btn {
    background-color: darkgrey;
    color: #fff;
}

.disabled-btn:hover {
    background-color: darkgrey;
}

$topnav-background-color: #2f73ba;
  .navbar {
      background-color: #FFF;
      padding: 5px 20px 5px 20px;
          // box-shadow: 1px 1px 10px #e3e6e8;
          border-bottom: 1px solid #c8ced3;
      .navbar-brand {
          color: #FFF;
          display: flex;
          align-items: center;
          img {
              height: 40px !important;
          }
          span {
              color: #000;
              padding-left: 10px;
          }
      }
      .header {
          .nav-item {
              .dropdown-menu-right {
                  left: auto !important;
              }
          }
      }
      .nav-item>a {
          color: #212529;
          &:hover {
              color: #212529;
              cursor: pointer;
          }
          &:after {
              display: none;
          }
          i {
              font-size: 20px;
          }
      }
      .has-wifi {
        color: green;
      }
      .no-wifi {
        color: red;
      }
      .update-mid {
          color: orange;
      }
      .update-low {
          color: green;
      }
      .update-high {
          color: red;
      }
  }
  .messages {
      width: 300px;
      .media {
          border-bottom: 1px solid #ddd;
          padding: 5px 10px;
          &:last-child {
              border-bottom: none;
          }
      }
      .media-body {
          h5 {
              font-size: 13px;
              font-weight: 600;
          }
          .small {
              margin: 0;
          }
          .last {
              font-size: 12px;
              margin: 0;
          }
      }
  }
  .common_url {
      background: #4c4fb4;
      position: fixed;
      top: 80px;
      z-index: 100;
      width: 100%;
      padding: 15px 100px 14px 240px;
      p {
          margin-bottom: 0;
          color: #fff;
      }
  }


span.hover-tip {
    position: relative
}

span.hover-tip span {
    display: none;
    padding-right: 0px;
    left: -1em;
}

span.hover-tip span:hover {
  .dropdown-item {
    background-color: transparent;
  }
}

span.hover-tip span:active {
  color: #000 !important;
}

span.hover-tip-active .dropdown-menu {
  padding-right: 1em;
}

span.hover-tip-active span {
  display: block;
  width: 100%;
  min-width: 20em;
  top: 2em;
  left: -10em;
  clear: both;
  color: #212529;
  text-align: inherit;
  white-space: nowrap;
}

.online-now {
  padding-left: 1em;
}

@keyframes pulse {
  5%, 15% {
    -webkit-transform: scale(1.2);
    -ms-transform: scale(1.2);
    transform: scale(1.2);
  }
  /* 10% {
    -webkit-transform: scale(0.9);
    -ms-transform: scale(0.9);
    transform: scale(0.9);
  } */
  0%, 10%, 20%, 100% {
    -webkit-transform: scale(1.0);
    -ms-transform: scale(1.0);
    transform: scale(1.0);
  }
}

.faa-pulse.animated,
.faa-pulse.animated-hover:hover,
.faa-parent.animated-hover:hover > .faa-pulse{
  -webkit-animation: pulse 5s linear infinite;
  animation: pulse 5s linear infinite;
}
