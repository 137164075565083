#learners_per_page_select_container {
  display: flex;
  grid-area: b;
  flex-flow: row;
  justify-content: center;
  label {
    margin: 0;
  }
}

.pagination_btn {
  color: #40aae3;
  margin: 0px;
  width: 90px;
  display: flex;
  cursor: pointer;
  background-color: transparent;
  border: none;
}

.pagination_btn.previous {
  justify-content: flex-end;
  margin-right: 5px;
}

.pagination_btn.next {
  justify-content: flex-start;
  margin-left: 5px;
}

.pagination_btn:disabled {
  cursor: initial;
}
