#teacher_basic_info {
  display: grid;
  border-top: 1px solid #dee2e6;
  border-bottom: 2px solid #dee2e6;
  p {
    font-weight: bold;
    margin: 0;
    padding: 0.75rem;
    hyphens: auto;
    word-break: break-word;
  }
}

.has_cloud_accts_bot {
  grid-template-columns: 20% 20% 20% 20% 20%;
}

.no_cloud_accts_bot {
  grid-template-columns: 25% 25% 25% 25%;
}

%teacher_acct_learners_list {
  display: grid;
  grid-template-columns:
    20px calc((100% - 110px) / 5) calc((100% - 110px) / 5) calc(
      (100% - 110px) / 5
    )
    calc((100% - 110px) / 5) calc((100% - 110px) / 5) 90px;
  text-align: center;
}

#teacher_acct_learners_list_hr {
  @extend %teacher_acct_learners_list;
  border-top: 1px solid #dee2e6;
  border-bottom: 2px solid #dee2e6;
  span,
  p {
    align-self: center;
    justify-self: center;
  }

  p {
    font-weight: bold;
    margin: 0;
    padding: 0.75rem;
  }
}

.teacher_acct_learners_list_row {
  @extend %teacher_acct_learners_list;
  border-bottom: 1px solid rgba(0, 0, 0, 0.125);
  p {
    margin: 0;
    padding: 0.75rem;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}

#impact_stats_container {
  display: grid;
  grid-template-columns: calc((100% - 40px) / 2) calc((100% - 40px) / 2);
  column-gap: 40px;
  height: 360px;
}

#empty-pie-text {
  display: flex;
  justify-content: center;
  align-content: center;
  flex-direction: column;
  text-align: center;
  font-weight: bold;
}

.manage-teacher {
  .carousel_container {
    width: 100%;
    height: fit-content;
    margin: 0px;
  }

  .carousel_btns_container {
    display: none;
    flex-flow: row;
    justify-content: center;
    width: 100%;
  }

  .carousel_btn {
    width: 20px;
    height: 20px;
    background-color: #40aae3;
    border-radius: 50%;
    border: 2px solid #40aae3;
    margin: 5px;
    cursor: pointer;
  }

  .carousel_btn.unselected {
    background-color: white;
    border: 2px solid #7e7e7e;
  }

  .carousel_chart {
    position: absolute;
    transition: left 0.5s;
    width: 100%;
    height: 100%;
  }

  .carousel_charts_wrapper {
    justify-content: center;
    display: flex;
    flex-flow: wrap;
  }

  .summary-and-chart {
    width: 300px;
    margin: 0px 10px 0px 3px;
  }
}

@media screen and (max-width: 700px) {
  // triggers the impact pie chart carousel format
  .teacher_acct_carousel {
    display: block;
    margin: auto;
    width: 300px;
    position: relative;
    overflow: hidden;
  }

  .manage-teacher {
    .carousel_charts_wrapper {
      height: 400px;
    }

    .carousel_btns_container {
      display: flex;
    }
  }

  .summary-and-chart {
    position: absolute;
    top: 0;
    margin: 0;
    transition: left 0.5s;
  }

  .chart_1 {
    left: 0px;
  }

  .chart_2 {
    left: 300px;
  }

  .chart_3 {
    left: 600px;
  }
}
