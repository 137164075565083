.line_chart_tooltip_question_text {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 5;
  -webkit-box-orient: vertical;
}

#chartjs-tooltip-overtime {
  z-index: 999;
  width: 50%;
  opacity: 1;
  position: absolute;
  background: white;
  color: #666;
  border-radius: 3px;
  border: silver solid 2px;
  -webkit-transition: all 0.1s ease;
  transition: all 0.1s ease;
  pointer-events: none;
  border: 2px solid grey;
  opacity: 1;
  right: calc(50% + 50px);
  top: 275px;
  padding-left: 6px;
  padding-right: 3px;
  p {
    margin: 0;
  }
}

.chartjs-tooltip-key {
  display: inline-block;
  width: 10px;
  height: 10px;
}

.hide-hover {
  display: none;
}
