#info_popup_common_border {
  font-weight: normal;
}

#info_popup_common_heading {
  padding: 15px 15px;
  text-align: left;
}

#info_popup_common_border,
#info_popup_header_text,
#info_popup_form_content,
#info_popup_text {
  padding: 0px;
}

#info_popup_text {
  margin-bottom: 1rem;
}
