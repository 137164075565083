.modal {
  position: fixed;
  top: 0;
  left: 0;
  width:100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.6);
}

.modal-main {
  position:fixed;
  background: white;
  width: 80%;
  height: auto;
  top:50%;
  left:50%;
  transform: translate(-50%,-50%);
  border-radius: 18px;
  padding: 40px 60px;
}

.display-block {
  display: block;
}

.display-none {
  display: none;
}

.sleep-countdown {
  margin-top: 36px;
}

#keep-alive-btn{
  margin: 20px auto;
}