%robots_table_row_shared {
  display: grid;
  min-width: 975px;
  grid-template-columns:
    50px 90px 85px 100px calc((100% - 445px) / 5) calc((100% - 445px) / 5) calc(
      (100% - 445px) / 5
    )
    calc((100% - 445px) / 5) calc((100% - 445px) / 5) 120px;
  border-bottom: 1px solid rgba(0, 0, 0, 0.125);

  div {
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0px 5px 0px 5px;

    .common_border {
      display: block;
      div {
        display: block;
        height: auto;
      }
    }
  }

  p {
    margin: 0;
  }
}
.filter-default,
.filter-with-buttons {
  display: flex;
  align-items: center;
}

.filter-with-buttons {
  margin-left: 30.3rem;
}

.btn {
  margin-right: 0.5rem;
  width: auto;
}

.btn:last-child {
  margin-right: 0;
}
#robot_search_results_container {
  overflow-x: hidden; //hides 2nd scrollbar
}

/* Media Queries for Responsiveness */
@media (max-width: 1163px) {
  .filter-with-buttons {
    display: flex;
    align-items: center;
    margin-top: -0.45rem;
  }

  #search-robots-filter {
    display: flex;
    align-items: center;
    padding: 0.5rem;
    margin-left: 25rem;
  }
}

@media (max-width: 1094px) {
  .filter-with-buttons {
    display: flex;
    align-items: center;
    margin-top: -0.45rem;
  }

  #search-robots-filter {
    display: flex;
    align-items: center;
    padding: 0.5rem;
    margin-left: 15rem;
  }
}

@media (max-width: 992px) {
  .filter-with-buttons {
    display: flex;
    align-items: center;
    margin-top: -0.45rem;
  }

  #search-robots-filter {
    display: flex;
    align-items: center;
    margin-left: 10rem;
  }

  .btn {
    font-size: 0.9rem;
    padding: 0.5rem 1rem;
  }
}

@media (max-width: 855px) {
  .filter-with-buttons {
    display: flex;
    align-items: center;
    margin-top: -0.45rem;
  }

  #search-robots-filter {
    display: flex;
    align-items: center;
    padding: 0.5rem;
    margin-left: 5rem;
  }
}

@media (max-width: 775px) {
  .filter-with-buttons {
    display: flex;
    align-items: center;
    margin-top: -0.1rem;
  }

  #deselect_all_robots_btn,
  #cancel_add_robot_popup {
    margin-bottom: 1rem;
  }

  #search-robots-filter {
    display: flex;
    align-items: center;
    margin-left: 1rem;
    flex-direction: row;
  }

  .btn {
    width: auto;
    margin-bottom: 0;
    margin-right: 0.5rem;
  }

  .btn:last-child {
    margin-right: 0;
    margin-top: 1rem;
  }
}

@media (max-width: 576px) {
  .filter-with-buttons,
  #search-robots-filter {
    display: flex;
    align-items: center;
  }

  #both-buttons {
    margin-left: -10vw;
  }

  #search-robots-filter {
    margin-right: auto;
    flex-direction: row;
    width: 50%;
    padding: 1rem;
    margin-left: -4.5rem;
  }

  .btn {
    width: auto;
    margin-bottom: 0;
    margin-right: 0.5rem;
  }

  .btn:last-child {
    margin-right: 0;
    margin-top: 1rem;
  }
}
@media (max-width: 525px) {
  .filter-with-buttons,
  #search-robots-filter {
    display: flex;
    align-items: center;
  }

  #both-buttons {
    margin-left: -10vw;
  }
}

#robots_table_row_subscription_hrs {
  @extend %robots_table_row_shared;
  font-weight: bold;
  background-color: rgba(0, 0, 0, 0.05);
  border-top: 1px solid rgba(0, 0, 0, 0.125);
  p {
    padding: 10px 2px;
    text-overflow: clip;
    overflow: hidden;
    hyphens: auto;
  }
}

.robots_table_row_subscription {
  @extend %robots_table_row_shared;

  p {
    padding: 8px 2px;
    text-overflow: clip;
    overflow: hidden;
    hyphens: auto;
  }
}

.larger-checkbox {
  width: 25px !important;
  height: 25px;
}
