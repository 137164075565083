#create_assignment_step_four {
  text-align: center;
  height: 100%;
  overflow-y: auto;
}

#step_four_tables_container {
  display: grid;
  grid-template-areas:
    "a b"
    "c d";
  grid-gap: 20px;
  height: 100%;
  min-height: 250px;
  overflow-y: auto;
  grid-template-columns: calc((100% - 20px) / 2) calc((100% - 20px) / 2);
  grid-template-rows: 20px auto;
  flex-grow: 1;

  #step_four_lessons_table,
  #step_four_learners_table {
    border: 1px solid #c4c4c4;
    border-radius: 5px;
  }

  p {
    margin: 0;
  }

  #step_four_lessons_table,
  #step_four_learners_table {
    overflow-y: auto;

    #step_four_lessons_table_header,
    #step_four_learners_table_header {
      font-weight: bold;
    }

    #step_four_lessons_table_header,
    .step_four_lessons_table_row,
    #step_four_learners_table_header,
    .step_four_learners_table_row {
      display: grid;
      padding: 2px;
      align-items: center;

      .gray_row {
        background-color: #c4c4c4;
      }

      .light_gray_row {
        background-color: #f2f2f2;
      }
    }

    #step_four_lessons_table_header {
      grid-template-columns:
        20px calc((100% - 60px) / 3) calc((100% - 60px) / 3) calc(
          (100% - 60px) / 3
        )
        40px;
    }

    .step_four_lessons_table_row.tut_row {
      grid-template-columns:
        30px calc((100% - 70px) / 3) calc((100% - 70px) / 3) calc(
          (100% - 70px) / 3
        )
        40px;
      background-color: #f2f2f2;
      border-top: 1px solid #dfe2e6;
      border-bottom: 1px solid #dfe2e6;
    }

    .step_four_lessons_table_row.asm_row {
      margin-left: 30px;
      grid-template-columns:
        0px calc((100% - 40px) / 3) calc((100% - 40px) / 3) calc(
          (100% - 40px) / 3
        )
        40px;
      background-color: #e2e2e2;
      border: 1px solid #cbced2;
      border-radius: 0px 0px 10px 10px;
      margin-bottom: 5px;
    }
  }

  #step_four_learners_table {
    grid-template-rows: 20px auto;
    #step_four_learners_table_header,
    .step_four_learners_table_row {
      grid-template-columns:
        20px calc((100% - 120px) / 2) calc((100% - 120px) / 2)
        100px;
    }

    .step_four_learners_table_row {
      padding: 10px 0px 10px 0px;
    }
  }
}

@media screen and (max-width: 992px) {
  #step_four_tables_container {
    overflow-y: unset !important;
    min-height: unset;
    height: fit-content;
    grid-template-columns: 100%;
    grid-template-rows: 20px auto 20px auto;
    grid-template-areas:
      "a"
      "c"
      "b" "d";
  }

  #step_four_lessons_table,
  #step_four_learners_table {
    overflow-y: unset !important;
    min-height: unset;
    height: fit-content;
  }
}

@media screen and (max-height: 610px) {
  #step_four_tables_container {
    overflow-y: unset !important;
    min-height: unset;
  }

  #step_four_lessons_table,
  #step_four_learners_table {
    overflow-y: unset !important;
    min-height: unset;
    height: auto;
  }
}

@media screen and (max-width: 775px) {
  // mega zoom-in mode
  .step_four_lesson_name_date_container {
    display: flex;
    align-items: baseline;
    justify-content: center;
    flex-flow: wrap;
    grid-column-gap: 20px;
    margin: auto auto 10px auto;

    h5,
    h6,
    p {
      margin: 0;
    }
  }
}
