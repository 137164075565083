.sales_detail_table_tbody {
  tr,
  td {
    text-align: left;
  }
}
#sales-acp-add-button {
  margin-top: 0.5rem;
  margin-bottom: 0.5rem;
}
#content-package-select {
  margin-top: 0.15rem;
  border: none;
  border-color: none;
}
