#new-label-grid {
  .new-label-row {
    display: grid;
    grid-template-columns: 75px auto;
    grid-gap: 10px;
    width: 100%;
    align-items: baseline;
    padding: 5px 0 0 0;
  }
}
