%orgs_list_table_gridlayout {
  display: grid;
  grid-template-columns:
    40px calc((100% - 370px) / 5) 80px 90px 60px calc((100% - 370px) / 5)
    calc((100% - 370px) / 5) calc((100% - 370px) / 5) calc((100% - 370px) / 5) 100px;
  min-width: 630px;
}

#orgs_list_table {
  #orgs_list_table_hr_row {
    @extend %orgs_list_table_gridlayout;
    border-bottom: 1px solid #dee2e6;
    padding: 10px 0px 10px 0px;
  }

  .orgs_list_table_data_row {
    @extend %orgs_list_table_gridlayout;
    border-top: 1px solid #dee2e6;
  }

  .orgs_list_table_data_container {
    min-width: 630px;
  }

  .orgs_list_table_data_hr {
    font-weight: bold;
    padding: 0px 5px 0px 5px;
  }

  .orgs_list_table_data {
    padding: 5px;
    text-align: center;
  }
}

.th_sortable {
  display: flex !important;
  flex-flow: row;
  justify-content: center;
  p {
    margin: 0;
    width: fit-content;
  }
}

.red {
  color: #cb444a;
  background-color: #f3d8da;
}

.yellow {
  color: #b6a402;
  background-color: #fdf2d1;
}

.green {
  color: #1c842f;
  background-color: #d9ecdb;
}

@media only screen and (max-width: 1295px) {
  %orgs_list_table_gridlayout {
    display: grid;
    grid-template-columns:
      40px calc((100% - 270px) / 5) 80px 90px 60px calc((100% - 270px) / 5)
      calc((100% - 270px) / 5) calc((100% - 270px) / 5) calc((100% - 270px) / 5);
  }

  [assignment-data-title]:after {
    left: 0px;
    right: unset;
    bottom: unset;
    top: -30px;
  }
}
