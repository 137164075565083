#reports_search_bar_container {
  position: relative;
}

#reports_search_bar_results {
  position: absolute;
  width: 300px;
  left: 0px;
  //top: 45px;
  background-color: white;
  z-index: 1;
  border: 1px solid gray;
  max-height: 300px;
  overflow: auto;

  div {
    padding: 5px;
  }

  .search_option {
    cursor: pointer;
  }

  .search_option:hover {
    background-color: rgba(0, 0, 0, 0.1);
  }

  span {
    color: gray;
  }
}
