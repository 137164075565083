div.showPW {
  display: flex;
  border: 1px solid #ced4da;
  border-radius: 5px;
  overflow: hidden;
}

div.showPW button {
  padding: 0;
  color: #fff;
  margin: 0;
  width: 50px;
  background: #e4e5e6;
  display: flex;
  justify-content: center;
  align-items: center;
}

.disabled-btn {
  background-color: darkgrey;
  color: #fff;
}

.disabled-btn:hover {
  background-color: darkgrey;
}

%class-grid-row-shared-teacher {
  display: grid;
  grid-template-columns:
    20px calc((100% - 695px) / 3) calc((100% - 695px) / 3) calc(
      (100% - 695px) / 3
    )
    110px 100px 100px 115px 170px;
  padding: 10px;
  grid-gap: 10px;
  border-bottom: 1px solid rgba(0, 0, 0, 0.125);

  p {
    margin: 0;
  }
}

// ----------- manage teachers page only ------------
%manage_teacher_classes_row {
  display: grid;
  grid-template-columns:
    20px calc((100% - 250px) / 4) calc((100% - 250px) / 4)
    calc((100% - 250px) / 4) calc((100% - 250px) / 4) 170px;
  padding: 10px;
  grid-gap: 10px;
  border-bottom: 1px solid rgba(0, 0, 0, 0.125);
  p {
    margin: 0;
  }
}

.manage_teacher_classes_row {
  @extend %manage_teacher_classes_row;
  font-weight: bold;
  align-items: center;
  p,
  span {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }

  span {
    text-align: center;
  }
}

.manage_teacher_classes_fields_row {
  @extend %manage_teacher_classes_row;
  background-color: #f2f2f2;
  padding: 0px 10px 0px 10px;
  p {
    padding: 10px 0px 10px 0px;
    margin: auto 0 auto;
  }

  span {
    margin: auto 0 auto;
    text-align: center;
  }
}

// ---------------------------------

.class-grid-row-caption-container-teacher {
  @extend %class-grid-row-shared-teacher;
  font-weight: bold;
  align-items: center;
  p,
  span {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }

  span {
    text-align: center;
  }
}

.class-grid-row-fields-container-teacher {
  @extend %class-grid-row-shared-teacher;
  background-color: #f2f2f2;
  padding: 0px 10px 0px 10px;
  p {
    height: fit-content;
    padding: 10px 0px 10px 0px;
    margin: auto 0 auto;
  }

  span {
    margin: auto 0 auto;
    text-align: center;
  }
}

.actions_btns_container_grid {
  display: grid;
  grid-gap: 10px;
  //align-items: center;
}

// --------------------------

@media only screen and (max-width: 1295px) {
  %class-grid-row-shared-teacher {
    grid-template-columns:
      20px calc((100% - 400px) / 3) calc((100% - 400px) / 3) calc(
        (100% - 400px) / 3
      )
      110px 100px 100px;
  }

  // ----------- manage teachers page only ------------
  %manage_teacher_classes_row {
    grid-template-columns:
      20px calc((100% - 80px) / 4) calc((100% - 80px) / 4)
      calc((100% - 80px) / 4) calc((100% - 80px) / 4);
  }
  // ---------------------------------

  .actions_btns_container_grid {
    margin-bottom: 10px;
  }
}
