.expanded_assignment_whitebox {
  background-color: #87b2c7;
  padding: 3px 0px 1px 0px;
  margin-bottom: 20px;
  border-radius: 0px 0px 10px 10px;

  .expanded_assignment_contents {
    background-color: #87b2c7;

    .expanded_assignment_white_interior {
      background-color: white;
      margin: 0px 20px 20px 20px;
      padding: 10px;
      display: grid;
      grid-gap: 10px;
      grid-template-columns: calc(50% - 10px) calc(50% - 10px);

      .maxHeight-graybox-filled {
        max-height: 600px;
      }

      .maxHeight-graybox-empty {
        max-height: 200px;
      }

      .expanded_assignment_graybox {
        padding: 10px;
        display: flex;
        flex-flow: column;
        background-color: #f3f3f3;
        transition: max-height 0.5s;
        -webkit-transition: max-height 0.5s;

        #assignment_select_lesson_dropdown {
          max-width: 320px;
          margin: auto;
        }

        .info_table {
          margin-top: 10px;
          text-align: left;

          b {
            margin-right: 10px;
          }
        }

        .learners_lessons_list_table_container {
          border: 1px solid #cbcbcb;
          border-radius: 5px;
          display: flex;
          flex-flow: column;
          overflow-y: auto;
          max-height: none;
          height: fit-content;

          p {
            margin: 0;
            height: 100%;
          }

          .header_label,
          .footer_label {
            display: flex;
            align-items: flex-end;
            justify-content: left;
          }

          .learners_lessons_list_table_hr,
          .learners_lessons_list_table_row,
          .learners_lessons_list_table_ft {
            display: grid;
            grid-template-columns: 230px auto;
            padding: 0px 10px 0px 10px;
            border-bottom: 1px solid #cbcbcb;
            overflow-x: auto;
          }

          .learners_lessons_list_table_hr {
            background-color: white;
            align-items: flex-end;
            height: 45px;
          }

          .learners_lessons_list_table_rows_container {
            background-color: white;
            flex: 1;
            overflow-y: auto;

            .learners_lessons_list_table_row {
              min-height: 40px;

              p {
                display: flex;
                align-items: center;
              }

              .data {
                justify-content: center;
                max-width: 100px;
                margin: auto;
              }
            }
          }

          .learners_lessons_list_table_ft {
            background-color: white;
            align-items: center;
            border-bottom: none;
            border-top: 1px solid #cbcbcb;
            height: 40px;

            p {
              display: flex;
              align-items: center;
            }

            .data {
              justify-content: center;
              max-width: 100px;
              width: 100%;
              margin: auto;
            }
          }

          .learners_lessons_list_table_empty {
            padding: 10px;
            text-align: center;
          }
        }
      }
    }
  }
}

@media screen and (max-width: 992px) {
  .expanded_assignment_whitebox {
    .expanded_assignment_contents {
      .expanded_assignment_white_interior {
        grid-template-columns: 100%;
        grid-template-rows: auto auto;

        .maxHeight-graybox-filled {
          max-height: 480px;
        }

        .expanded_assignment_graybox {
          height: fit-content;
        }
      }
    }
  }
}
