.has-wifi {
  color: green;
}

.no-wifi {
  color: red;
}

.wifi-status-icon {
  margin-left: 24px;
}

.default-wifi {
  color: black;
}

#wifi-status-box {
  border: 2px solid #b0bac3;
  padding: 12px 24px;
  margin: 12px auto;
  width: 100%;
  text-align: center;
}

#access-status-box {
  border: 2px solid #b0bac3;
  padding: 36px 24px 12px 24px;
  margin: 12px auto;
  width: 100%;
  text-align: left;
}

#check-access-link {
  margin-top: 24px;
  text-align: center;
  display: block;
  font-size: 12px;
}

#access-status-box .resource-access-value {
  color: #2196f3;
  font-size: 18px;
}

#check-access-btn {
  width: 60%;
  margin: 18px auto;
  display: block;
}

.center-div {
  margin: 4px auto !important;
}

/* ABii Resource Access  */

.info-circle-btn {
  border: none;
  background-color: transparent;
  width: fit-content;
  height: fit-content;
}

.info-circle {
  color: #666;
  margin: 0px;
  font-size: 16px;
}

.info-circle:hover {
  cursor: pointer;
}

#info-modal {
  position: fixed;
  z-index: 1;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  overflow: auto;
  background-color: rgb(0, 0, 0);
  background-color: rgba(0, 0, 0, 0.4);
}

#info-modal-inner {
  background-color: #fefefe;
  margin: 20% auto;
  padding: 20px 30px;
  border: 1px solid #888;
  border-radius: 8px;
  width: 60%;
  min-height: 250px;
}

.info-close-btn {
  color: #666;
  cursor: pointer;
  float: right;
  font-size: 24px;
}

.info-modal-text {
  margin-top: 50px;
  font-size: 18px;
}

.status-icon {
  margin-right: 6px;
}
