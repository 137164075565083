#reports-learner-stats-table {
  display: flex;
  flex-flow: column;
  width: 100%;

  [data-title]:after {
    left: 0px !important;
    max-width: 600px;
    min-width: 400px;
    overflow: auto;
    word-wrap: break-word;
    white-space: normal;
  }

  .reports-learner-stats-table-header {
    width: 100%;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }

  .label-row {
    display: grid;
    grid-gap: 10px;
    align-items: center;
    grid-template-columns: 45% calc((55% - 30px) / 3) calc((55% - 30px) / 3) calc(
        (55% - 30px) / 3
      );
    border-top: 1px solid rgba(0, 0, 0, 0.2);
    padding: 5px;
  }

  .label-cell {
    span {
      overflow: hidden;
      width: fit-content;
      text-overflow: ellipsis;
      display: -webkit-box;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;
    }
  }

  .label-cell,
  .data-cell {
    padding: 5px;
  }
}
#stats-table-page [data-title]:hover:after,
.label_container [data-title]:hover:after {
  opacity: 1;
  transition: all 0.1s ease 0.5s;
  visibility: visible;
}

#stats-table-page [data-title]:after,
.label_container [data-title]:hover:after {
  content: attr(data-title);
  background-color: #2f353a;
  color: white;
  font-size: 120%;
  position: absolute;
  padding: 3px 6px;
  bottom: -1.6em;
  left: 0px;
  white-space: nowrap;
  box-shadow: 1px 1px 3px #222222;
  opacity: 0;
  border-radius: 5px;
  z-index: 99999;
  visibility: hidden;
}

#stats-table-page [data-title],
.label_container [data-title] {
  position: relative;
}

.stats-table-info {
  color: #666;
  margin-left: 8px;
}

.stats-table-info:hover {
  cursor: pointer;
}

#stats-table-info-modal {
  position: fixed;
  z-index: 1;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  overflow: auto;
  background-color: rgb(0, 0, 0);
  background-color: rgba(0, 0, 0, 0.4);
}

#stats-table-info-modal-inner {
  background-color: #fefefe;
  padding: 20px 30px;
  border: 1px solid #888;
  border-radius: 8px;
  height: 450px;
  left: 125px;
}

@media only screen and (max-width: 990px) {
  #stats-table-info-modal-inner {
    position: initial;
    width: 75%;
    margin: 25% auto;
  }
}

@media only screen and (min-width: 991px) {
  #stats-table-info-modal-inner {
    position: relative;
    width: 60%;
    margin: 15% auto;
  }
}

.info-close-btn {
  color: #666;
  cursor: pointer;
  float: right;
  font-size: 24px;
}

.stats-table-modal-text {
  font-size: 16px;
}

#stats-table-page .top-row {
  margin-top: 30px;
}

.like-link {
  color: #20a8d8;
  text-decoration: none;
}

.like-link:hover {
  cursor: pointer;
}
