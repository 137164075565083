/**
 * The donut is a circle-shaped chart that is color coded according
 * to percentages of data, much like a pie chart.
 * @returns {html} a tiny donut chart in the lesson assignment info
 * collapsible row.
 * @source https://stackoverflow.com/a/28345637/9457623
 */

.donut {
  -webkit-transform: rotate(-90deg);
  transform: rotate(-90deg);
}

.circle_colored {
  stroke-dasharray: 130; /* this value is the pixel circumference of the circle */
}

.donut_container {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
}

.donut_caption {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  word-break: normal !important;
  font-size: 13px;
}
