.contacts_popup_graybox,
#delete_popup_graybox {
  position: fixed;
  top: 0;
  right: 0;
  width: calc(100% - 180px);
  height: 100%;
  background-color: rgba(0, 0, 0, 0.3);
  z-index: 1;
}

%contact_whitebox {
  position: fixed;
  background-color: white;
  border-radius: 10px;
  display: flex;
  flex-flow: column;
}

#contacts_popup_whitebox,
#create_org_popup_whitebox {
  @extend %contact_whitebox;
  top: 80px;
  left: calc(10% + 180px);
  width: calc(80% - 180px);
  min-height: 50%;
  max-height: calc(100% - 100px);
}

#make_contact_popup_whitebox,
#delete_popup_whitebox {
  @extend %contact_whitebox;
  top: 100px;
  left: calc(15% + 180px);
  width: calc(70% - 180px);
  min-height: 30%;
}

#delete_popup_whitebox {
  top: 120px;
  min-height: unset;
}

@media screen and (max-width: 992px) {
  .contacts_popup_graybox,
  #delete_popup_graybox {
    width: 100%;
  }
  #contacts_popup_whitebox,
  #make_contact_popup_whitebox,
  #delete_popup_whitebox,
  #create_org_popup_whitebox {
    width: 100%;
    left: 0;
  }
}

#contacts_container {
  padding: 5px;
  overflow-y: auto;
}

%gray_outline {
  border: 1px solid #dfe2e6;
  border-radius: 5px;
}

%contact_gridlayout {
  display: grid;
  grid-gap: 2px;
  padding: 5px;
}

.other_contacts_header_flexbox {
  @extend %contact_gridlayout;
  grid-template-columns: max-content auto;
  align-items: center;
  grid-gap: 10px;
}

#contacts_filter_container {
  @extend %gray_outline;
  display: grid;
  grid-template-columns:
    70px calc((100% - 170px - 8%) / 3) calc((100% - 170px - 8%) / 3)
    calc((100% - 170px - 8%) / 3) 100px;
  align-items: baseline;
  grid-gap: 2%;
  padding: 0 2%;
}

.contact_box {
  @extend %gray_outline;
  @extend %contact_gridlayout;
  grid-template-columns: auto 120px;
  align-items: center;
}

.contact_box_info {
  @extend %contact_gridlayout;
  grid-template-columns: calc(100% / 3) calc(100% / 3) calc(100% / 3);
  grid-template-areas:
    "a b c"
    "d e f"
    "d g h";
  justify-content: flex-start;
  align-items: flex-start;

  p {
    margin: 0;
  }
}

.contact_btns_container {
  display: inline-flex;
  height: fit-content;
  justify-content: center;
  justify-self: flex-start;

  button {
    margin: 2px;
  }
}
