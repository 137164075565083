%robot_summary {
  display: grid;
  grid-template-columns: calc(100% / 3) calc(100% / 3) calc(100% / 3);
  text-align: center;

  p {
    margin: 0px;
    padding: 0.75rem;
  }
}

#robot_summary_hr {
  @extend %robot_summary;
  border-bottom: 2px solid #dee2e6;
  border-top: 1px solid #dee2e6;
  font-weight: bold;
}

.robot_summary_row {
  @extend %robot_summary;
}
