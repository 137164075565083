#consolidated_reports_content {
  display: grid;
  grid-template-rows: 70px auto;
  grid-template-columns: 100%;
}

#tabs_container {
  display: flex;
  flex-flow: row;
  align-items: flex-end;
  border-bottom: 2px solid rgb(34, 167, 215);

  h4 {
    margin: 0;
  }
}

.tab {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  width: 150px;
  background-color: #b2dbee;
  border-radius: 5px 5px 0px 0px;
  border: 0px;
  border-right: 1px solid rgb(34, 167, 215);
  cursor: pointer;
}

#consolidated_report_body {
  border-style: solid;
  border-color: transparent rgba(0, 0, 0, 0.2) rgba(0, 0, 0, 0.2)
    rgba(0, 0, 0, 0.2);
  padding: 15px;
}

.bar_chart_shadow {
  opacity: 0.1;
  width: 100%;
  height: 100%;
  border: 2px solid gray;
}
