// ---------------------------------

.common_dashboard_bg {
  padding: 25px !important;
}

#learners-wrapper {
  padding-top: 24px;
  padding-bottom: 150px !important;
}

.webkit-line-box {
  display: -webkit-inline-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
}

.class-header-grid {
  display: grid;
  grid-template-columns: auto max-content;
  align-items: center;
  background-color: rgb(178, 219, 238);
  border-radius: 5px 5px 0px 0px;
}

.class-name-and-triangle-grid {
  width: 100%;
  margin-bottom: 0px;
  justify-self: start;
  display: flex;
  font-size: 20px;
  align-items: center;
  cursor: pointer;
}

.triangle-up {
  width: 0;
  height: 0;
  border-left: 10px solid transparent;
  border-right: 10px solid transparent;
  border-top: 10px solid #4b4b4b;
}

.triangle-down {
  width: 0;
  height: 0;
  padding: 0;
  border-left: 10px solid #4b4b4b;
  border-right: 10px solid transparent;
  border-top: 10px solid transparent;
  border-bottom: 10px solid transparent;
}

.sortable {
  cursor: pointer;
  position: relative;
  display: flex;
  align-items: center;
  margin-bottom: 0px;
}

th.sortable {
  cursor: pointer;
  position: relative;
  display: table-cell;
  align-items: center;
  margin-bottom: 0px;
}

th.sortable:before {
  cursor: pointer;
  position: relative;
  display: inline-block;
  align-items: center;
  margin-bottom: 0px;
}

.css-1wy0on6 {
  /* overrides some open source css for the grade dropdowns */
  display: -webkit-box !important;
}

.student_name_flex_container {
  position: relative;
  overflow: visible;
}

.css-26l3-qy-menu {
  position: absolute;
  z-index: 2;
}

div.showPW {
  /* overrides to prevent stretch of pw input on zoom */
  height: fit-content;
}

#learner_username_hr {
  justify-self: left;
  align-items: center;
  width: -webkit-fill-available;

  p {
    display: block;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }
}

#avg_lessons_hr {
  justify-self: center;

  p {
    text-overflow: ellipsis;
    overflow: hidden;
    // Addition lines for 2 line or multiline ellipsis
    display: -webkit-box !important;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
    white-space: normal;
  }
}

// -------------- action btn hover text ---------------

%action-btn-on-hover-txt {
  background-color: #2f353a;
  color: white;
  font-size: 120%;
  position: absolute;
  padding: 3px 6px;
  bottom: -1.6em;
  white-space: nowrap;
  box-shadow: 1px 1px 3px #222222;
  opacity: 0;
  border-radius: 5px;
  z-index: 99999;
  visibility: hidden;
}

[data-title]:hover:after {
  opacity: 1;
  transition: all 0.1s ease 0.5s;
  visibility: visible;
}

[data-title]:after {
  @extend %action-btn-on-hover-txt;
  content: attr(data-title);
  left: -50px;
}

[class-btn-data-title]:after {
  @extend %action-btn-on-hover-txt;
  content: attr(class-btn-data-title);
  left: unset;
}

[class-btn-data-title]:hover:after {
  opacity: 1;
  transition: all 0.1s ease 0.5s;
  visibility: visible;
}

[data-title] {
  position: relative;
}

[class-btn-data-title] {
  position: relative;
}

[class-btn-data-title]:after {
  left: unset;
  right: 0px;
  bottom: unset;
  top: -30px;
}

// --------------------------

@media only screen and (max-width: 1295px) {
  [class-btn-data-title]:after {
    left: 0px;
    right: unset;
    bottom: unset;
    top: -30px;
  }
}
