[data-title]:hover:after {
  opacity: 1;
  transition: all 0.1s ease 0.5s;
  visibility: visible;
}

[data-title]:after {
  content: attr(data-title);
  background-color: #2f353a;
  color: white;
  font-size: 120%;
  position: absolute;
  padding: 3px 6px;
  bottom: -1.6em;
  left: -50px;
  white-space: nowrap;
  box-shadow: 1px 1px 3px #222222;
  opacity: 0;
  border-radius: 5px;
  z-index: 99999;
  visibility: hidden;
}

[data-title] {
  position: relative;
}

.card,
.batch-row {
  margin-bottom: 2em;
}

.intro-vids {
  margin: 56px auto;
  display: block;
}

.form_icons {
  width: 100%;
  max-width: 250px;
}

.table-scroll-wrapper {
  max-height: 550px;
  overflow: auto;
}

.table-scroll-wrapper::-webkit-scrollbar {
  width: 16px;
}

.table-scroll-wrapper::-webkit-scrollbar-track {
  background: #f1f1f1;
}

.table-scroll-wrapper::-webkit-scrollbar-thumb {
  background: #888;
  height: 43px;
}

.table-scroll-wrapper::-webkit-scrollbar-thumb:hover {
  background: #555;
}

.line-break {
  margin: 0 !important;
  height: 0;
  width: 100%;
}
