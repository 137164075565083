.error {
  color: #db2269;
  width: 250px;
  margin-left: auto;
  margin-right: auto;
  font-size: 0.9em;
  display: flex;
  margin-bottom: 5px;
  text-align: left;
  /*justify-content: center;
  align-items: center;
  text-align: center;*/
}
