.error {
  color: #db2269;
  font-size: 0.625em;
  display: flex;
  margin-bottom: 5px;
  //justify-content: center;
  //align-items: center;
  text-align: center;
}

.row {
  margin-bottom: 5px;
}

.form-group {
  margin-bottom: 20px;
  position: relative;
  .has_error {
    position: absolute;
    p {
      margin-bottom: 0;
      font-family: "SanFranciscoText-Regular";
    }
  }
  label {
    padding: 0;
    color: #fff;
    margin: 0;
    a {
      color: #f9930d;
    }
  }
  input::-webkit-input-placeholder {
    color: #666 !important;
    font-size: 14px;
  }
  input:-moz-placeholder {
    /* Firefox 18- */
    color: #666 !important;
    font-size: 14px;
  }
  input::-moz-placeholder {
    /* Firefox 19+ */
    color: #666 !important;
    font-size: 14px;
  }
  input:-ms-input-placeholder {
    color: #666 !important;
    font-size: 14px;
  }
}

.org_form_icons {
  width: 100%;
  max-width: 350px;
  min-height: 40px;
  display: flex;
  border: 1px solid #ced4da;
  border-radius: 5px;
  overflow: hidden;
  label {
    width: 50px;
    background: #e4e5e6;
    display: flex;
    justify-content: center;
    align-items: center;
    i {
      color: gray;
    }
  }
}

.org_form_checkbox_div {
  width: 100%;
  max-width: 350px;
  display: flex;
  border-radius: 5px;
  overflow: hidden;
  label {
    color: #495057;
    display: flex;
    justify-content: left;
    align-items: left;
  }
  input {
    max-width: 70px;
    margin-left: 30px;
    max-height: 25px;
  }
}

.org_form_errors,
.create_org_fields_hr {
  width: 100%;
  max-width: 350px;
}

.org_form_errors {
  color: #db2269;
  font-size: 0.9em;
  padding-left: 10px;
  width: fit-content;
  justify-self: right;
}

#create_org_form {
  padding: 10px 15px 5px;
  display: grid;
  grid-template-rows: max-content max-content auto;
  overflow: auto;
}

#create_org_name_container {
  display: grid;
  grid-template-columns: 300px auto;
  margin-top: 20px;
}

@media screen and (max-width: 992px) {
  #create_org_name_container {
    display: grid;
    grid-template-columns: unset;
    grid-template-rows: max-content auto;
    margin-top: 20px;
  }
}

#create_org_info_container {
  display: flex;
  flex-flow: row;
  align-items: flex-start;
  justify-content: center;
}

.create_org_contact_inputs_container {
  display: grid;
  grid-template-columns: 50% 50%;
}

.create_org_fields {
  margin: 5px;
}

#create_org_btns_container {
  margin: 10px;
  display: flex;
  flex-flow: row;
  align-items: center;
  justify-content: center;
}

#create_org_contacts_container {
  border-top: 2px solid lightgray;
  margin: 5px;
  padding: 5px;
  overflow-y: auto;
}

.create_org_hr_error_container {
  display: grid;
  grid-template-columns: max-content auto;
  max-width: 350px;
  margin: auto;
}
