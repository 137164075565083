.learner_tab_header_container {
  display: grid;
  grid-template-columns: 45% 55%;
  align-items: center;
}

.learner_tab_body {
  display: grid;
  grid-template-columns: 55% 45%;
  grid-template-areas:
    "a b"
    "c c"
    "d d";
}

@media screen and (max-width: 992px) {
  .learner_tab_body {
    display: grid;
    grid-template-columns: 100%;
    grid-template-areas:
      "a"
      "b"
      "c"
      "d";
  }

  .learner_tab_header_container {
    display: flex;
    flex-flow: column;
    text-align: center;
  }
}
