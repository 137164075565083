video.welcome-vids {
	margin: 40px 5%;
}

.btns-row {
	margin-top: 20px;
}

.pointer-hover:hover {
	cursor: pointer;
}