$topnav-background-color: #2f353a;
@media screen and (max-width: 992px) {
    .showSidebar {
        left: 180px !important;
    }
}
.sidebar {
    border-radius: 0;
    position: fixed;
    z-index: 1000;
    top: 60px;
    left: 180px;
    width: 180px;
    margin-left: -180px;
    border: none;
    border-radius: 0;
    overflow-y: auto;
    background-color: $topnav-background-color;
    bottom: 0;
    overflow-x: hidden;
    padding-top: 53px;
    -webkit-transition: all 0.2s ease-in-out;
    -moz-transition: all 0.2s ease-in-out;
    -ms-transition: all 0.2s ease-in-out;
    -o-transition: all 0.2s ease-in-out;
    transition: all 0.2s ease-in-out;
    // border-top: 1px solid rgba(255,255,255,0.3);
    .list-group {
        a.list-group-item, button.list-group-item {
            background: transparent;
            border: 0;
            border-radius: 0;
            color: #ffffff;
            text-decoration: none;
            font-size: 14px;
            text-align: left;
            z-index: 10;
            .fa {
                margin-right: 10px;
                width: 18px;
            }
        }
        a:hover, button:hover {
            color: #ffffff;
            background: rgba(58, 66, 72, 0.75);
            cursor: pointer;
        }
        a.router-link-active {
            color: #ffffff;
            background: #3a4248;
            i {
                color: #2ca9d6;
            }
        }
        .header-fields {
            padding-top: 10px;

            > .list-group-item:first-child {
                border-top: 1px solid rgba(255, 255, 255, 0.2);
            }
        }
    }
    .sidebar-dropdown {
        *:focus {
            border-radius: none;
            border: none;
        }
        .panel-title {
            font-size: 1rem;
            height: 50px;
            margin-bottom: 0;
            a {
                color: #999;
                text-decoration: none;
                font-weight: 400;
                background: $topnav-background-color;
                span {
                    position: relative;
                    display: block;
                    padding: 0.75rem 1.5rem;
                    padding-top: 1rem;
                }
            }
            a:hover,
            a:focus {
                color: #fff;
                outline: none;
                outline-offset: -2px;
            }
        }
        .panel-title:hover {
            background: darken($topnav-background-color, 5%);
        }
        .panel-collapse {
            border-radious: 0;
            border: none;
            .panel-body {
                .list-group-item {
                    border-radius: 0;
                    background-color: $topnav-background-color;
                    border: 0 solid transparent;
                    a {
                        color: #999;
                    }
                    a:hover {
                        color: #fff;
                    }
                }
                .list-group-item:hover {
                    background: darken($topnav-background-color, 5%);
                }
            }
        }
    }
}
.nested-menu {
    .list-group-item {
        cursor: pointer;
    }
    .nested {
        list-style-type: none;
    }
    ul.submenu {
        display: none;
        height: 0;
    }
    & .expand {
        ul.submenu {
            display: block;
            list-style-type: none;
            height: auto;
            li {
                a {
                    color: #fff;
                    padding: 10px;
                    display: block;
                }
            }
        }
    }
}
@media screen and (max-width: 992px) {
    .sidebar {
        top: 54px;
        left: 0px;
    }
}
@media print {
    .sidebar {
        display: none !important;
    }
}
@media (min-width: 992px) {
    .header-fields {
        display: none;
    }
}

::-webkit-scrollbar {
    width: 8px;
}

::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 0px rgba(255, 255, 255, 1);
    border-radius: 3px;
}

::-webkit-scrollbar-thumb {
    border-radius: 3px;
    -webkit-box-shadow: inset 0 0 3px rgba(255, 255, 255, 1);
}
